import React, { useEffect, useState } from "react";
import "./CreateUser.css";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetUserRoles,
  getpermitions,
  UploadUserImage,
  getTlData,
  addUser,
  getManagerData,
  getOneUserById,
} from "proxyUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UserEdit = () => {
  const { id } = useParams();

  const [userDataFromApi, setUserDataFromApi] = useState(null);

  const [roleFromTable, setRoleFromTable] = useState();
  const [permission, setPermission] = useState();
  const [selected, setSelected] = useState([]);
  const [manager_id, setManager_id] = useState();
  const [tl_id, setTl_id] = useState();
  const [userRole, setUserRole] = useState("");
  console.log(userRole, 888);
  const [managerDataApi, setManagerDataApi] = useState();
  const [tlDataApi, setTlDataApi] = useState();
  const [addEmp, setAddEmp] = useState(false);
  const [addTl, setAddTl] = useState(false);
  const [status, setStatus] = useState();
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);

  const [submiteed, setSubmiteed] = useState(false);

  const [image, setImage] = useState();
  const [preview, setPreview] = useState(null);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    password: "",
  });

  const navigate = useNavigate();
  const permissionid = [];

  if (selected[0]) {
    for (let index = 0; index < selected.length; index++) {
      const id = selected[index].value;
      permissionid.push(id);
    }
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const GetUserDataFromApi = async (id) => {
    const url = `${getOneUserById}/${id}`;
    axios
      .get(url, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((data) => {
        const allData = data.data;
        setUserDataFromApi(allData);
      });
  };
  console.log(userDataFromApi, 88);

  const permissionidString = JSON.stringify(permissionid);
  const currentUser_id = useSelector((state) => state.user.user);

  const addRolePermition = (userRole) => {
    if (userRole.label === "user") {
      setAddEmp(true);
      setAddTl(false);
    } else if (userRole.label === "tl") {
      setAddTl(true);
      setAddEmp(false);
    } else if (userRole.label === "manager") {
      setAddTl(false);
      setAddEmp(false);
    } else if (userRole.label === "admin") {
      setAddTl(false);
      setAddEmp(false);
    }
  };

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "name" && value.length > 50) {
      return;
    } else if (name === "phone" && value.length > 20) {
      return;
    } else if (name === "password" && value.length > 19) {
      return;
    }
    setUser({ ...user, [name]: value });
  };

  const handleEmail = (e) => {
    if (e.target.value.length > 40) {
      return;
    }

    setEmail(e.target.value);
    // validate email and set validEmail state accordingly
    setValidEmail(/^\S+@\S+\.\S+$/.test(e.target.value));
  };

  // handle email field focus...........{
  function handleFocusEmail() {
    setValidEmail(true);
  }
  // ..................}

  const getPermissions = async () => {
    axios
      .get(getpermitions, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((data) => {
        const allPermission = data.data;
        setPermission(allPermission);
      });
  };

  const roleData = async () => {
    axios
      .get(GetUserRoles, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((role) => {
        const allRole = role.data;
        setRoleFromTable(allRole);
      });
  };

  useEffect(() => {
    tlData();
    managerData();
    roleData();
    getPermissions();
    addRolePermition(userRole);
    GetUserDataFromApi(id);
  }, [id, userRole]);

  useEffect(() => {
    setUser({
      ...user,
      name: userDataFromApi ? userDataFromApi.name : "",
      phone: userDataFromApi ? userDataFromApi.phone : "",
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataFromApi]);
  useEffect(() => {
    setEmail(userDataFromApi ? userDataFromApi.email : "");
    setStatus(userDataFromApi ? userDataFromApi.status : "");
    setUserRole(userDataFromApi ? userDataFromApi.role_id : "");
  }, [userDataFromApi]);

  const addUserFunction = async (e) => {
    e.preventDefault();
    setSubmiteed(true);
    if (!user.name || !email || !user.password || !user.phone) {
      toast.error("please fill the data!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);

      return;
    } else if (!image) {
      toast.error("please Choose User Image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
      return;
    } else if (!email) {
      toast.error("Email is not correct!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
      return;
    } else if (!validEmail) {
      console.log("Please enter valid Email!");
      toast.error("Please enter valid Email!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);

      return;
    }
    //creating form data
    const formData = new FormData();
    formData.append("image", image);

    await axios
      .post(UploadUserImage, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data.imageUrl, 88888);
        if (res.status === 200) {
          axios
            .post(
              addUser,
              {
                name: user.name,
                phone: user.phone,
                email: email,
                password: user.password,
                user_id: currentUser_id.userInfo.id,
                role_id: userRole,
                manager_id: manager_id,
                teamleader_id: tl_id,
                permission_id: permissionidString,
                status: status,
                image: res ? res.data.imageUrl : "",
              },
              {
                withCredentials: true,
              }
            )
            .then((res) => {
              console.log(res.status);
              if (res.status === 200) {
                console.log("Form submiteed succsesfully");
                toast.success("Form submiteed succsesfully!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                const delayfrfresh = () => {
                  navigate(`/${currentUser_id.userRole.name}/users`);
                };
                // eslint-disable-next-line
                const delayRef = setTimeout(delayfrfresh, 3100);
              }
            })
            .catch((err) => {
              console.log(err.response.status);
              if (err.response.status === 400) {
                console.log("please fill the data");
                toast.error("please fill the data!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (err.response.status === 401) {
                console.log("email allready exist");
                toast.error("email allready exist!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                console.log("internal server error");
                toast.error("internal server error!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        }
      })
      .catch((err) => {
        setSubmiteed(false);
        console.log(err);
      });
  };
  // PERMISSION OPTION .......... STARTING..........{
  const permissionOption = [];
  if (permission) {
    for (let i = 0; i < permission.length; i++) {
      const id = permission[i].id;
      const permissionName = permission[i].name;
      const is_dissabled = permission[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: permissionName,
        disabled: is_dissabled,
      };
      permissionOption.push(option);
    }
  } 
  // PERMISSION OPTION .......... ENDING..........}
  // ROLES .......... STARTING..........{
  const managerData = async () => {
    axios
      .get(getManagerData, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((data) => {
        const allData = data.data;
        setManagerDataApi(allData);
      });
  };
  const tlData = async () => {
    axios
      .get(getTlData, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((data) => {
        const allData = data.data;
        setTlDataApi(allData);
      });
  };
  const managerOption = [
    {
      value: 0,
      label: "none",
    },
  ];
  const tlOption = [
    {
      value: 0,
      label: "none",
    },
  ];
  const options = [];
  if (roleFromTable) {
    for (let i = 0; i < roleFromTable.length; i++) {
      const id = roleFromTable[i].id;
      const roleName = roleFromTable[i].name;
      const option = {
        value: id,
        label: roleName,
      };
      options.push(option);
    }
  }
  if (managerDataApi) {
    for (let i = 0; i < managerDataApi.length; i++) {
      const id = managerDataApi[i].id;
      const Name = managerDataApi[i].name;
      const option = {
        value: id,
        label: Name,
      };
      managerOption.push(option);
    }
  }
  if (tlDataApi) {
    for (let i = 0; i < tlDataApi.length; i++) {
      const id = tlDataApi[i].id;
      const Name = tlDataApi[i].name;
      const option = {
        value: id,
        label: Name,
      };
      tlOption.push(option);
    }
  }

  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    setImage(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  if (!tl_id) {
    setTl_id({ value: 0, label: "none" });
  }
  if (!manager_id) {
    setManager_id({ value: 0, label: "none" });
  }
  // console.log(selected);
  // ROLES .......... STARTING..........}
  return (
    <>
      <div className=" mt-7 rounded-2xl py-4 dark:bg-navy-800   ">
        <form
          action=""
          method="POST"
          className="create-user-form bg-white   dark:bg-navy-800"
        >
          <div className="user-image-wrapper  ">
            <div className="user-image bg-primaryBlue shadow-lg dark:bg-navy-900">
              {preview ? (
                <img className="cover object-cover" src={preview} alt="" />
              ) : (
                <img className="cover object-cover" src="/profile.jpg" alt="" />
              )}
            </div>
            <div className="user-image-upload relative top-[70px]  ">
              <label
                className=" mx-auto flex w-fit cursor-pointer items-center justify-center gap-2 rounded-xl bg-primaryBlue px-7 py-2 text-xl text-white"
                htmlFor="userImage"
              >
                <AiOutlineCloudUpload className="text-4xl" /> Choose Image
              </label>
              <input
                className="hidden"
                type="file"
                name=""
                onChange={handleImage}
                id="userImage"
              />
            </div>
          </div>
          <div className="create-form-wrapper">
            <div className="field">
              <div className="input">
                <label
                  htmlFor="userName"
                  className="text-navy-900 dark:text-white"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="User name"
                  id="userName"
                  name="name"
                  value={user.name}
                  onChange={handleInput}
                />
              </div>
              <div className="input">
                <label
                  htmlFor="phone"
                  className="text-navy-900 dark:text-white"
                >
                  Phone
                </label>
                <input
                  type="number"
                  placeholder="User phone number"
                  name="phone"
                  id="phone"
                  value={user.phone}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="field mt-[-12px]">
              <div className="input relative ">
                <label
                  htmlFor="email"
                  className="text-navy-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="text"
                  placeholder="User email"
                  onFocus={handleFocusEmail}
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmail}
                />
                {!validEmail && (
                  <p
                    className=" emailvalid  absolute left-0 mt-[3px] ml-2 text-[14px]"
                    style={{ color: "red" }}
                  >
                    Please enter a valid email
                  </p>
                )}
              </div>
              <div className="input">
                {" "}
                <label
                  htmlFor="password"
                  className="text-navy-900 dark:text-white"
                >
                  password
                </label>
                <input
                  type="text"
                  placeholder="User password"
                  name="password"
                  id="password"
                  value={user.password}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className=" flex w-full  gap-2">
              <div className=" mx-2 w-[50%]">
                <label htmlFor="role" className="text-navy-900 dark:text-white">
                  Role
                </label>
                <Select
                  value={userRole}
                  onChange={setUserRole}
                  className="mt-2 capitalize"
                  id="role"
                  options={options}
                />
              </div>
              <div className="mx-2 w-[50%]">
                <label
                  htmlFor="country"
                  className="text-navy-900 dark:text-white"
                >
                  permissions
                </label>
                <MultiSelect
                  options={permissionOption}
                  value={selected}
                  onChange={setSelected}
                  data={selected}
                  labelledBy="Select"
                  className=" mt-2"
                />
              </div>
            </div>
            {addEmp ? (
              <div className="mt-3 flex w-full gap-4">
                <div className=" mx-2 w-[50%]">
                  <label
                    htmlFor="country"
                    className="text-navy-900 dark:text-white"
                  >
                    Manager
                  </label>
                  <Select
                    defaultValue={manager_id}
                    onChange={setManager_id}
                    className="mt-2 capitalize "
                    options={managerOption}
                  />
                </div>
                <div className=" mr-2  w-[50%]">
                  <label
                    htmlFor="country"
                    className="text-navy-900 dark:text-white"
                  >
                    Team Leader
                  </label>
                  <Select
                    defaultValue={tl_id}
                    onChange={setTl_id}
                    className="mt-2 capitalize "
                    options={tlOption}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {addTl ? (
              <div className="mt-3 flex w-full gap-4">
                <div className=" mx-2 w-[50%]">
                  <label
                    htmlFor="country"
                    className="text-navy-900 dark:text-white"
                  >
                    Manager
                  </label>
                  <Select
                    defaultValue={manager_id}
                    onChange={setManager_id}
                    className="mt-2 capitalize"
                    options={managerOption}
                  />
                </div>
                <div className=" mr-2  w-[50%]"></div>
              </div>
            ) : (
              <></>
            )}
            <div className="field">
              <div className="input relative">
                <label htmlFor="status-select">Status</label>
                <select
                  id="status-select"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Disabled</option>
                </select>
              </div>
              <div className="input relative"></div>
            </div>

            <div className="bg-transperent mx-2 mt-5  flex items-center justify-center">
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="field cursor-pointer "
                    onClick={addUserFunction}
                  >
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button type="submit"> Submit </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserEdit;
