import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { categoryAllData, catDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/common/Pagination";
export default function Index() {
  const [catData, setCatData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(10);

  // getting all data tags starts----------------------
  const getAllCategorysData = async () => {
    await axios
      .get(categoryAllData, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data.data, "casasasaatDatacatData");

        setCatData(res.data.data);
      })
      .catch((err) => {
        setCatData(null);
      });
  };

  const handleDelete = async (id) => {
    await axios
      .delete(`${catDelete}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Category Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllCategorysData();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.success("Category Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllCategorysData();
    // console.log(catData,'catDatacatData')
  }, []);

  const indexOfLastRecord = (currentPage + 1) * recordsPerPage;
  console.log(indexOfLastRecord, "Indexoflastrecorrdsssssss");
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  console.log(indexOfFirstRecord, "Indexoffirstrecorddsssssssssss");
  const currentRecords = catData.slice(indexOfFirstRecord, indexOfLastRecord);
  console.log(
    currentRecords,
    "These are my currentrecordsssssssssssssssssssss"
  );
  const nPages = Math.ceil(catData.length / recordsPerPage);
  console.log(nPages, "These are the npagessssssssss");

  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Category List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/categories/add"}
            >
              {" "}
              Add Category{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Image
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Name
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Slug
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Description
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Status
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Parent Category
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {catData ? (
                <>
                  {currentRecords.map((category) => (
                    <tr key={category.id}>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        <img
                          className="h-10 w-10 rounded"
                          src={category.image}
                        />{" "}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {category.name}{" "}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        /{category.slug}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {category.description}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        <p className=" w-fit  rounded-full bg-blueSecondary py-1 px-4 text-sm text-white">
                          Active
                        </p>
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800    dark:text-white">
                        --{" "}
                      </td>
                      <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                        <Link
                          to={`/admin/categories/edit/${category.id}`}
                          className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                        >
                          <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                        </Link>

                        <button
                          className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                          onClick={() => handleDelete(category.id)}
                        >
                          <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    N/A
                  </td>
                </>
              )}
            </tbody>
          </table>
        </div>
        {console.log(currentPage, "This is my currentPageeeeeeeeeeeeee")}
        {nPages != 1 ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </>
  );
}
