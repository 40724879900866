export const columnsDataDevelopment = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "TECH",
      accessor: "tech",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];
  
  export const columnsDataCheck = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataColumns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataComplex = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];
  
  export const BookingDataTable = [
  
    {
      Header: "FIRST NAME",
      accessor: "firstName",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "PHONE",
      accessor: "cell_phone_no",
    },
    {
      Header: "PNR",
      accessor: "pnr",
    },
    {
      Header: "AIRLINE",
      accessor: "airlineIsuued",
    },
   
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "ACTION",
      accessor: "id",
    },
   
  ];
  
  export const UserDataTable = [
  
    {
      Header: "NAME",
      accessor: "username",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "PHONE",
      accessor: "cell_phone_no",
    },
   
    {
      Header: "ACTION",
      accessor: "id",
    },
   
  ];
  export const BlogsDataTable = [
    {
      Header: "TITLE",
      accessor: "title",
    },
    {
      Header: "SLUG",
      accessor: "slug",
    },
    {
      Header: "Metakeywords",
      accessor: "metakeywords",
    },
    {
      Header: "Image",
      accessor: "image",
    },
   {
      Header: "ACTION",
      accessor: "id",
    },
  ];
  