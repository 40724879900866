import React, { useState, useEffect } from "react";
import { tagsAllData, tagDelete } from "proxyUrl";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import Pagination from "components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "components/common/LoaderComp";

export default function Index() {
  const [tagData, setTagData] = useState([]);
  const [submited, setSubmited] = useState(true);
  // pagination start
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(10);

  // pagination end
  // getting all data tags starts----------------------
  const getAllTagsData = async () => {
    await axios
      .get(tagsAllData, {
        withCredentials: true,
      })
      .then((res) => {
        setTagData(res.data.data);
        setSubmited(false);
      })
      .catch((err) => {
        setTagData(null);
      });
  };
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllTagsData();
  }, []);

  const handleDelete = async (id) => {
    await axios
      .delete(`${tagDelete}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Tag Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllTagsData();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.success("Tag Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  // Gagan
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tagData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(tagData.length / recordsPerPage);

  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Tags List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/tag/add"}
            >
              {" "}
              Add Tag{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Name
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Slug
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Description
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Status
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <LoaderComp submited={submited} />

              {tagData[0] ? (
                <>
                  {currentRecords.map((tag) => (
                    <tr key={tag.id}>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {tag.name}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {tag.slug}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {tag.description}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        <p className=" w-fit  rounded-full bg-blueSecondary py-1 px-4 text-sm text-white">
                          Active
                        </p>
                      </td>
                      <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                        <Link
                          to={`/admin/tag/edit/${tag.id}`}
                          className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                          // onClick={() => handleEdit(category.id)}
                        >
                          <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                        </Link>
                        <button
                          className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                          onClick={() => handleDelete(tag.id)}
                        >
                          <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    N/A
                  </td>
                </>
              )}
            </tbody>
          </table>
        </div>
        {nPages != 1 ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </>
  );
}
