/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      //  console.log(route.display);
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/manager" ||
        route.layout === "/user" ||
        route.layout === "/superadmin" ||
        route.layout === "/tl"
      ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div
              className={`${
                activeRoute(route.path) === true
                  ? ` ${route.display} relative mb-3   flex rounded-md bg-lightPrimary  px-3 py-2 duration-200 hover:cursor-pointer dark:bg-navy-900 dark:text-white`
                  : `${route.display} hover:text-dark relative mb-3 flex rounded-md px-3 dark:active:bg-navy-800  py-2 duration-200 hover:cursor-pointer hover:bg-lightPrimary `
              }`}
            >
              <li
                className="my-[3px] flex cursor-pointer items-center "
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold  ml-4  text-white dark:white"
                      : "font-bold ml-4   text-white"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "} 
                </span>
                <p
                  className={`leading-1 flex ms-4 ${
                    activeRoute(route.path) === true
                      ? "font-bold text-white dark:text-white/70"
                      : "font-medium text-white"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute top-[-1px] left-0 h-12 w-2  rounded-md bg-blueSecondary dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
