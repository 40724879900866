import { HiX } from "react-icons/hi";
import Links from "./components/Links";

const Sidebar = ({ open, onClose, routes }) => {
  return (
    <div
      className={`sm:none  border-[3px]  border-blueSecondary duration-175 shadow-white/9 fixed  !z-50   m-4  flex min-h-[96vh] w-[280px] flex-col  rounded-xl bg-primaryBlue px-6  pb-10 shadow-2xl transition-all dark:bg-navy-800  dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      <div className={` flex items-center justify-center pt-5 `}>
        <img src="/lms.png" className=" w-[100px] " alt="" />
      </div>

      {/* Nav item */}
      <ul className="mb-auto  pt-5">
        <Links routes={routes} />
      </ul>
    </div>
  );
};

export default Sidebar;
