import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "ProtectedRoute";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ErrorPage from "views/ErrorPage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createTheme, ThemeProvider } from '@mui/material';
const App = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#265a40', // Replace this with your custom color
      },
      background: {
        default: '#265a40', // Replace this with your custom color
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <DndProvider backend={HTML5Backend}>
            <div className="    ">
              <div className=" fixed !w-full !h-full bg-white dark:bg-navy-900 top-0 left-0 z-[-1] " ></div>
              <Routes>
                <Route
                  path="auth/*"
                  element={<ProtectedRoute Component={AuthLayout} />}
                />
                <Route
                  path="admin/*"
                  element={<ProtectedRoute Component={AdminLayout} />}
                />
                <Route path="/" element={<Navigate to="/auth" replace />} />
                <Route path="/*" element={<ErrorPage />} />
              </Routes>
            </div>
          </DndProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
