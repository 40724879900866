import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "tailwindcss/tailwind.css";
const ImageUpload = ({ setImageFile, initialImage }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  // useEffect(() => {
  //   // Set initial image data when it is available
  //   if (initialImage) {
  //     setPreviewUrl(initialImage);
  //   }
  // }, [initialImage]);
  // console.log(previewUrl, "This is my inital imagesssssssssss");

  const onDrop = (acceptedFiles) => {
    const image = acceptedFiles[0];
    setSelectedImage(image);
    setImageFile(image);
    setPreviewUrl(URL.createObjectURL(image));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  return (
    <div className="full mx-auto mt-10 h-fit">
      <p className=" mb-2 font-semibold text-primaryBlue ">Feature Image</p>
      <div
        {...getRootProps()}
        className={`rounded-md border-2 border-dashed p-4 ${
          isDragActive ? "border-blue-500" : "border-gray-300"
        } flex cursor-pointer flex-col items-center justify-center`}
      >
        <input {...getInputProps()} />
        <p className="text-center">
          {isDragActive
            ? "Drop the image here"
            : "Drag and drop an image here, or click to select"}
        </p>
        {initialImage ? (
          <div className="mt-4  ">
            <h2 className="text-lg font-semibold">Selected Image:</h2>
            <img
              src={initialImage}
              alt="Selected"
              className="mt-2 h-[200px] rounded-md"
            />
          </div>
        ) : (
          selectedImage && (
            <div className="mt-4  ">
              <h2 className="text-lg font-semibold">Selected Image:</h2>
              <img
                src={previewUrl}
                alt="Selected"
                className="mt-2 h-[200px] rounded-md"
              />
            </div>
          )
        )}
        {/* {selectedImage && (
          <div className="mt-4  ">
            <h2 className="text-lg font-semibold">Selected Image:</h2>
            <img
              src={previewUrl}
              alt="Selected"
              className="mt-2 h-[200px] rounded-md"
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
export default ImageUpload;
