import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { RiEditCircleFill, RiShowersFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { chapterAllData, chapterDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "components/common/LoaderComp";

import Pagination from "../../../components/common/Pagination";
export default function Index() {
  const [catData, setCatData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(10);
  const [submited, setSubmited] = useState(true);

  // getting all data tags starts----------------------
  const getAllCategorysData = async () => {
    await axios
      .get(chapterAllData, {
        withCredentials: true,
      })
      .then((res) => {
        setCatData(res.data.data);
        setSubmited(false);
      })
      .catch((err) => {
        setCatData(null);
      });
  };

  // Gagan
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = catData
    ? catData.slice(indexOfFirstRecord, indexOfLastRecord)
    : "";
  const nPages = catData ? Math.ceil(catData?.length / recordsPerPage) : 0;

  const handleDelete = async (id) => {
    await axios
      .delete(`${chapterDelete}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Chapter Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllCategorysData();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.success("Chapter Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  useEffect(() => {
    getAllCategorysData();
  }, []);
  // getting all data tags ends----------------------

  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Chapter List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/chapter/add"}
            >
              {" "}
              Add Chapter{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Image
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Name
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Slug
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Grade
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Subject
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Status
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <LoaderComp submited={submited} />
              {currentRecords.length == 0 ? (
                <tr>
                  <td colSpan="6" class="text-center">
                    No data Found
                  </td>
                </tr>
              ) : currentRecords ? (
                <>
                  {currentRecords.map((chapter) => (
                    <tr key={chapter.id}>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {chapter.image ? (
                          <img
                            height="50"
                            width="50"
                            alt=""
                            src={`${chapter.image}`}
                          />
                        ) : (
                          <img
                            height="50"
                            width="50"
                            alt=""
                            src="/course.jpg"
                          />
                        )}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {chapter.name}{" "}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        /{chapter.slug}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {chapter.grade.name}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {chapter.subject.name}
                      </td>

                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {chapter.status === "active" ? (
                          <p className=" w-fit rounded-full  bg-blueSecondary py-1 px-4 text-sm text-white">
                            {chapter.status}
                          </p>
                        ) : (
                          <p className=" w-fit  rounded-full bg-red-700  py-1 px-4 text-sm text-white">
                            {chapter.status}
                          </p>
                        )}
                      </td>
                      <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                        <Link
                          to={`/admin/chapter/edit/${chapter.id}`}
                          className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                          // onClick={() => handleEdit(category.id)}
                        >
                          <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                        </Link>
                        {/* <button
                      className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                      // onClick={() => handleDelete(category.id)}
                    >
                      <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 "  />
                    </button> */}

                        <button
                          className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                          onClick={() => handleDelete(chapter.id)}
                        >
                          <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    No Data
                  </td>
                  <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                    N/A
                  </td>
                </>
              )}
            </tbody>
          </table>

          {nPages != 1 ? (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
