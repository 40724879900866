import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { questionShow } from "proxyUrl";
import "react-toastify/dist/ReactToastify.css";


export default function QuestionShow() {

  const [err, setErr] = useState();

  const [questionData, setQuestionData] = useState(null);
  const Navigate = useNavigate();


  // Get ID from URL
  const params = useParams();
  const { id } = params;

  const getQuestionDataId = async () => {

    await axios.get(`${questionShow}/${id}`, {
      withCredentials: true
    }).then(res => {
      setQuestionData(res.data);
    }).catch(err => {
      setQuestionData(null);
    })

  }

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    getQuestionDataId();
  }, [id]);


  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">

        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Question
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>

                <p>{questionData ? questionData?.QueData.questions : ''}</p>
              </div>
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                  Slug
                </label>
                <p>{questionData ? `/${questionData.QueData.slug}` : ''}</p>
              </div>

            </div>
            <div className="mb-4 flex gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="description"
                >
                  Type
                </label>
                <p>{

                  questionData ? questionData.QueData.type : ''
                }</p>
                <div dangerouslySetInnerHTML={{ __html: questionData ? questionData.QueData.description : '' }} />
              </div>


              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <label className="mb-2 block text-sm font-bold " htmlFor="status">
                    Status
                  </label>
                  <p>{questionData ? questionData.QueData.status : ''}</p>
                </div>

              </div>
            </div>

            <div className="mb-4 w-full  md:w-1/2 lg:w-full">
              <label className="mb-2 block text-sm font-bold " htmlFor="name">
                Option
              </label>
              <div>{questionData ? questionData.Queoption.map((data) => {
               return (
                  <>
                    <p key={data.id}> {data.id} .  {data.name}   {data.is_correct ?
                      <span>✔️</span> : ''}

                    </p>

                  </>
               )
                }) : ''}</div>
            </div>

          </div>

        </div>
        <div className={` relative !h-[550px]  w-[30%] overflow-y-scroll `}>
          {questionData ?
          <div
          className={`  ${
            isSticky
              ? "fixed top-[122px] right-7 !h-[550px] w-[22.6%] overflow-y-scroll "
              : "relative"
          } w-full rounded-md bg-white py-20 px-9`}
        >
         
          <div className=" sticky  top-0 flex flex-col  gap-3 ">
            <div className="mb-0 w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Grade
              </label>
              <div>{questionData ? questionData.Quegrade.map((data) => {
             return (
                <>
                  <p key={data.id}>   {data.grade.name}   
                  </p>

                </>
             )
              }) : ''}</div>
            </div>
            <div className="mb-0 w-full ">
              <label
                htmlFor="Subject"
                className="block text-sm font-medium text-gray-600"
              >
                Subject
              </label>
              <div>{questionData ? questionData.Quesubject.map((data) => {
             return (
                <>
                  <p key={data.id}>   {data.subject.name}   
                  </p>

                </>
             )
              }) : ''}</div>
            </div>

            <div className="mb-0 w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Topic
              </label>
              <p>{questionData.QueData ? questionData?.QueData?.topic?.name : '-'}</p>
            </div>

            <div className="mb-0 w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Sub Topic
              </label>
              <p>{questionData.QueData ? questionData?.QueData?.sub_topic?.sub_topic_name : '-'}</p>

            </div>

            <div className=" w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Category
              </label>
              <div>{questionData.Quecategory[0] ? questionData.Quecategory.map((data) => {
             return (
                <>
                  <p key={data.id}>   {data.category.name}   
                  </p>

                </>
             )
              }) : '-'}</div>
            </div>

            <div className=" w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Tag
              </label>

              <div>{questionData.Quetag[0] ? questionData.Quetag.map((data) => {
             return (
                <>
                  <p key={data.id}>   {data.tag.name}   
                  </p>

                </>
             )
              }) : '-'}</div>
            </div>

            {/* <div className=" w-full ">
              <label
                htmlFor="course"
                className="block text-sm font-medium text-gray-600"
              >
                Course
              </label>
            -
            </div> */}
          </div>
        </div>:
          ""}
          
        </div>
      </div>

    </>
  );
}
