/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  QuestionAllGet,
  gradeGet,
  getSingleGradeAllSubjects,
  topicGet,
  subTopicFilter,
  questionFilter,
} from "proxyUrl";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill, RiShowersFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import he from "he";
import Pagination from "../../../components/common/Pagination";
import Records from "./Record";
import LoaderComp from "components/common/LoaderComp";
import Select from "react-select";
import { useSelector } from 'react-redux'
export default function Index() {
  const userData = useSelector((state) => state.user.user);

  const [fromDate, setFromDate] = useState(new Date());
  const [err, setErr] = useState();
  const [filter, setFilter] = useState(0);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [gradeSlug, setGradeSlug] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [totalcount, setTotalcount] = useState(0);
  const [gradeData, setGradeData] = useState("");
  const [gradeData2, setGradeData2] = useState("");
  const [topicData, setTopicData] = useState("");
  const [subtopicData, setSubTopicData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);

  // fetching main question data start ---------------{
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(25);
  const [QuestionMainDataFromApi, setQuestionMainDataFromApi] = useState([]);

  const [submited, setSubmited] = useState(true);

  const handleGradeChange = async (e) => {
    setSelectedGrade(e);
    setSelectedSubject(null);
    setSelectedTopic(null);
    setSelectedSubTopic(null);
    setSelectedGrade(e);
    setGradeSlug(gradeData2.find((element) => element.id === e.value).slug);
  };
  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
        setGradeData2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };

  const subjectDataFetch = async () => {
    try {
      const response = await axios.post(
        getSingleGradeAllSubjects,
        { gradeSlug: gradeSlug },
        { withCredentials: true }
      );
      const subjects = response.data.data.subjects;
      console.log(subjects, "single grade subjects");

      // Format subjects for rendering
      const formattedSubjects = subjects.map((subject) => ({
        value: subject.id,
        label: subject.name, // You can format it as needed
      }));

      setSubjectData(formattedSubjects);
    } catch (error) {
      console.error(error);
      setSubjectData([]);
    }
  };

  const topicDataFetch = async () => {
    await axios
      .post(
        topicGet,
        {
          gradeId: selectedGrade.value,
          subjectId: selectedSubject.value,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };
  const subtopicDataFetch = async () => {
    await axios
      .post(
        subTopicFilter,
        {
          topicid: selectedTopic ? selectedTopic.value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setSubTopicData(res.data.formattedData);
      })
      .catch((err) => {
        console.log(err);
        setSubTopicData(null);
      });
  };

  const getdataFilter = async () => {
    await axios
      .post(
        questionFilter,
        {
          page: currentPage,
          type: 1,
          gradeId: selectedGrade ? selectedGrade.value : 0,
          subjectId: selectedSubject ? selectedSubject.value : 0,
          topicId: selectedTopic ? selectedTopic.value : 0,
          user_id: userData.userInfo.id,
          date: fromDate ? `${fromDate.getFullYear()}-0${fromDate.getMonth() + 1}-${fromDate.getDate()}` : null,

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // setTotalcount(res.data.totalCount); // Update total count with filtered data count
        setTotalcount(res.data.count);
        setFilter(1);
        setQuestionMainDataFromApi(res.data.data);
        setSubmited(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setQuestionMainDataFromApi([]);
      });
  };

  const getQuestionFun = async () => {
    //  setSubmited(true)
    await axios
      .post(QuestionAllGet, {
        page: currentPage,
        type: 0,
        user_id: userData.userInfo.id,
        date: fromDate ? `${fromDate.getFullYear()}-0${fromDate.getMonth() + 1}-${fromDate.getDate()}` : null,
      })
      .then((res) => {
        setLoading(false);
        setTotalcount(res.data.totalCount);
        setFilter(0);
        setQuestionMainDataFromApi(res.data.data);
        setSubmited(false);
      })
      .catch((err) => {
        setQuestionMainDataFromApi([]);
      });
  };

  //  Call the API when component mounts
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = QuestionMainDataFromApi?QuestionMainDataFromApi.slice(indexOfFirstRecord, indexOfLastRecord):0;

  const pagelength = totalcount ? totalcount : 0;

  const nPages = Math.ceil(pagelength / recordsPerPage);
  useEffect(() => {
    getQuestionFun();
  }, []);

  useLayoutEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);

  useEffect(() => {
    if (!submited) {
      getdataFilter();
    }
  }, [currentPage, selectedGrade, selectedTopic, selectedSubject]);

  useEffect(() => {
    // Fetch subject data when selectedGrade changes
    if (selectedGrade) {
      subjectDataFetch(selectedGrade.value);
    }
  }, [selectedGrade]);
  useEffect(() => {
    // gradeDataFetch();
    setFilter(1);
    if (filter !== 0) {
      setCurrentPage(0);
      getdataFilter();
      subjectDataFetch();
    }
  }, [selectedGrade]);
  useEffect(() => {
    if (filter !== 0) {
      setCurrentPage(0);
      getdataFilter();
      topicDataFetch();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (filter !== 0) {
      setCurrentPage(0);
      subtopicDataFetch();
      getdataFilter();
      topicDataFetch();
    }
  }, [selectedTopic]);
  useEffect(() => {
    setSubmited(true);
    if (filter === 0) {
      getQuestionFun();
    } else {
      getdataFilter();
    }
  }, [currentPage]);



  useEffect(() => {
    getQuestionFun()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  // --------------------------------------------}

  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <div className=" flex justify-start items-center gap-5 ">
            <h1 className="text-2xl font-bold dark:text-white  ">
              Questions List
            </h1>
            <div className=" rounded-lg border-l-2 pl-2 border-primaryBlue ">

              <DatePicker className=" dark:bg-navy-800 dark:text-white bg-white px-3 py-2 rounded-full " selected={fromDate} onChange={(date) => setFromDate(date)} />

            </div>

          </div>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/questions/add"}
            >
              {" "}
              Add Question{" "}
            </Link>
          </div>
        </div>
        <div class="mb-10 mt-5 grid grid-cols-4 gap-4">
          <div className="mb-0 w-full ">
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-600"
            >
              Grade
            </label>

            <Select
              defaultValue={selectedGrade}
              onChange={handleGradeChange}
              options={gradeData}
            />
            {err && err["grade"] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err["grade"]}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-0 w-full ">
            <label
              htmlFor="Subject"
              className="block text-sm font-medium text-gray-600"
            >
              Subject
            </label>
            <Select
              defaultValue={selectedSubject ? selectedSubject : null}
              onChange={setSelectedSubject}
              options={subjectData}
            />
            {err && err["subject"] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err["subject"]}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="mb-0 w-full ">
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-600"
            >
              Topic
            </label>
            <Select
              defaultValue={selectedTopic}
              onChange={setSelectedTopic}
              options={topicData ? topicData : []}
            />
            {err && err["topic"] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err["topic"]}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="mb-0 w-full ">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Sub Topic
                  </label>
                  <Select
                    defaultValue={selectedSubTopic}
                    onChange={setSelectedSubTopic}
                    options={subtopicData ? subtopicData : []}
                  />
                  {err && err["subtopic"] ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err["subtopic"]}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
        </div>
        <div className="overflow-x-auto">
          <LoaderComp submited={submited} />
          <Records
            nPages={25 * currentPage}
            getQuestionFun={getQuestionFun}
            data={QuestionMainDataFromApi}
          />
          {console.log(nPages, "This is my npagesssss cominggg")}
          {nPages !== 1 && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </>
  );
}
