import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  chapterDatalist,
  videoUpload,
  allQuestions,
  questionDatalistfilter,
  getQuestionDataDirectly,
  gradeGet,
  getSingleGradeAllSubjects,
  allactiveQuestion,
  GetCategoryAndSubcategory,
  courseAdd,
  chapterAllDataActive,
  imageAdd,
  chapterAllCategory,
  tagsAllData,
  questionFilter,
  getQuestionsByfilter,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./course.css";
import List from "../../../components/lecture/List";
import Select from "react-select";
import { ImCancelCircle } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import VideoDragDrop from "./DragComponents/VideoDragDrop";
import ImageUploader from "components/common/ImageUploader";

import DragDropModule from "./DragComponents/DragDropModule";
import { useNavigate } from "react-router-dom";

export default function CourseAdd() {
  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedcat, setSelectedcat] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [checkselectederror, setcheckselectederror] = useState(null);
  const [checkselectederror2, setcheckselectederror2] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [videoRedUrl, setVideoRedUrl] = useState([]);
  //console.log(videoRedUrl,"videoRedUrlvideoRedUrlvideoRedUrl");
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [CatSubData, setCatSubData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [image, setImage] = useState("");
  const [imagedata, setImageData] = useState(null);
  //console.log(imagedata,"jhgfdghj");
  const [TagData, setTagData] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [checked, setChecked] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsDefaultData, setQuestionsDefaultData] = useState([]);
  const [questionData, setQuestionData] = useState("");
  const [gradeData, setGradeData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [slug, setSlug] = useState(null);
  const [selectedQuestionsCount, setSelectedQuestionsCount] = useState(0);

  console.log(questionData, "questionData");

  // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->
  const getQuesstionsDefalult = async () => {
    const dataQ = await axios
      .get(getQuestionDataDirectly)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log("Error In Getting Question Data", err);
        return [];
      });

    setQuestionsDefaultData(dataQ);
  };
  useEffect(() => {
    getQuesstionsDefalult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->

  // const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [WhatWillLearn, setWhatWillLearn] = useState(null);
  const [Requirements, setRequirements] = useState(null);

  const [status, setStatus] = useState("active");
  const [Certified, setCertified] = useState("true");
  const [feature, setFeature] = useState("true");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [isQuwstionAddPopup, setIsQuwstionAddPopup] = useState(false);
  const [isQuwstionViewPopup, setIsQuwstionViewPopup] = useState(false);
  const [gradeData2, setGradeData2] = useState("");
  const [gradeSlug, setGradeSlug] = useState(null);

  const [isSticky, setIsSticky] = useState(false);

  const [videoFiles, setVideoFiles] = useState([]);
  const [videoUpdated, setVideoUpdate] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const handleGradeChange = async (e) => {
    setSelectedGrade(e);
    setSelectedSubject(null);
    console.log(e, "handle grade change");
    setSelectedGrade(e);
    console.log(gradeData2, "grade data raw dataaaa");
    console.log(
      gradeData2.find((element) => element.id === e.value).slug,
      "gradeData2[0].namegradeData2[0].name"
    );
    setGradeSlug(gradeData2.find((element) => element.id === e.value).slug);
  };

  const handleVideoFileChange = async (e) => {
    e.preventDefault();
    setVideoLoading(true);
    const chosenFiles = Array.prototype.slice.call(videoFiles);

    chosenFiles.map((data) => {
      if (!data) {
        alert("Please select a video file to upload.");
        return;
      }
      const chunkSize = 5 * 1024 * 1024;
      const totalChunks = Math.ceil(data.size / chunkSize);
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;
      const uploadNextChunk = async () => {
        if (chunkNumber < totalChunks) {
          const chunk = data.slice(start, end);
          console.log(chunk, "chunkchunk");
          const formData = new FormData();
          formData.append("Chunk", chunk);
          formData.append("ChunkNumber", chunkNumber);
          formData.append("TotalChunks", totalChunks);
          formData.append("FileName", data.name);
          formData.append("FileSize", data.size);
          // formData.append("File Size:", videoBuffer.length)

          try {
            const response = await axios
              .post(videoUpload, formData, {
                headers: {
                  "Content-type": "multipart/form-data",
                },
                withCredentials: true,
              })
              .then((res) => {
                if (res.status === 201) {
                  setVideoRedUrl((prevData) => [...prevData, res.data.url]);
                  setVideoLoading(false);
                  setVideoUpdate(true);
                  toast.success("Video uploaded Successfully", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  console.log(res, "resresres");
                  const temp = `Chunk ${
                    chunkNumber + 1
                  }/${totalChunks} uploaded successfully`;
                }

                chunkNumber++;
                start = end;
                end = start + chunkSize;
                uploadNextChunk();
              })
              .catch((err) => {
                console.log(err);
                setVideoLoading(false);
              });
          } catch (error) {
            setVideoLoading(false);
            console.error("Error uploading chunk:", error);
            toast.error("Error During the uploading the video!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          setVideoFile(null);
          setVideoLoading(false);
        }
      };

      uploadNextChunk();
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [chapterData, setChapterData] = useState(null);
  const [perchapterData, setPerChapterData] = useState(null);
  const [perOptionData, setPerOptionData] = useState(null);

  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
        setGradeData2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    try {
      const response = await axios.post(
        getSingleGradeAllSubjects,
        { gradeSlug: gradeSlug },
        { withCredentials: true }
      );
      const subjects = response.data.data.subjects;
      console.log(subjects, "single grade subjects");

      // Format subjects for rendering
      const formattedSubjects = subjects.map((subject) => ({
        value: subject.id,
        label: subject.name, // You can format it as needed
      }));

      setSubjectData(formattedSubjects);
    } catch (error) {
      console.error(error);
      setSubjectData([]);
    }
  };
  // Ck Editor Data Starts-----------------------{
  const QuestionDataFromEditor = (e) => {
    setQuestionData(e);
  };
  // Ck Editor Data Ends-----------------------}

  const getAllCategorysData = async () => {
    await axios
      .get(chapterAllDataActive, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log(res.data.array,'resres');
        setPerChapterData(res.data.array);
        setChapterData(res.data.array);
        // setChapterData(res.data.data);
      })
      .catch((err) => {
        setChapterData(null);
      });

    await axios
      .get(chapterAllCategory, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log(res.data.array,'resres');
        setoptionsCat(res.data.array);
        // setChapterData(res.data.data);
      })
      .catch((err) => {
        setoptionsCat(null);
      });
  };

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageLoadingUploading, setIsImageLoadingUploading] = useState(false);
  const handleSubmitImage = async (e) => {
    e.preventDefault();
    setIsImageLoadingUploading(true);
    let formData = new FormData();
    formData.append("file", image);

    axios
      .post(imageAdd, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((res) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(true);
        if (res.status === 201) {
          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setImageData(res.data.path);
          setIsImageUploaded(true);
          setErr(null);
        }
      })
      .catch((err) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(false);
        if (err.response.status === 400) {
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };
  useEffect(() => {
    setIsImageUploaded(false);
    setIsImageLoadingUploading(false);
  }, [image]);

  useEffect(() => {
    // Fetch subject data when selectedGrade changes
    if (selectedGrade) {
      subjectDataFetch(selectedGrade.value);
    }
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedQuestionsCount(selectedQuestions.length);
  }, [selectedQuestions]);

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        courseAdd,
        {
          name,
          slug,
          description,
          selectedcat,
          parentCategory,
          Requirements,
          WhatWillLearn,
          status,
          Certified,
          price,
          sale_price,
          selectedChapter,
          note,
          imagedata,
          selectedQuestions,
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
          videoRedUrl,
          feature,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("course Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() => {
          Router("/admin/course");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };

  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };
  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }
  const getChapterdata = async () => {
    axios
      .post(
        chapterDatalist,
        {
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res, "resresres");
        setChapterData(res.data.filterData);
      })
      .catch((err) => {
        console.log(err);
        setChapterData([]);
      });
  };
  const getQuestiondata = async () => {
    axios
      .post(
        questionDatalistfilter,
        {
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res, "setoptions questions");
        setoptions(res.data.filterData);
      })
      .catch((err) => {
        console.log(err);
        setoptions([]);
      });
  };

  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);
  useEffect(() => {
    getAllCategorysData();
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedQuestiondata(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedChapter.length > 0) {
      setSelectedChapter(selectedChapter);
    }
  }, [selectedChapter]);
  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);
  useEffect(() => {
    // getdataFilter();
    if (checked) {
      getChapterdata();
    }
  }, [selectedGrade]);
  useEffect(() => {
    // getdataFilter();
    if (checked) {
      getChapterdata();
    }
  }, [selectedSubject]);
  useEffect(() => {
    if (checked) {
      getChapterdata();
    }
  }, [selectedCategories]);

  console.log(questionsDefaultData, "questionsDefaultDataquestionsDefaultData");
  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Add Course
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Course Name"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                  Slug
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="slug"
                  type="text"
                  placeholder="course-slug"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                />
                {err && err.slug ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.slug}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                Video
              </label>

              <VideoDragDrop setVideoFiles={setVideoFiles} />
              {videoFiles[0] ? (
                <>
                  {videoLoading ? (
                    <>
                      <div className="mt-3 text-end ">
                        <button
                          disabled
                          className="rounded-md bg-blue-200 py-2 px-4 text-white hover:bg-blue-300 focus:outline-none"
                        >
                          Uploading....
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {videoUpdated ? (
                        <>
                          <div className="mt-3 text-end ">
                            <button className="rounded-md bg-green-500 py-2 px-4 text-white hover:bg-green-600 focus:outline-none">
                              Uploaded
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-3 text-end ">
                            <button
                              onClick={handleVideoFileChange}
                              className="rounded-md bg-blue-500 py-2 px-4 text-white hover:bg-blue-600 focus:outline-none"
                            >
                              Upload Videos
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* image uploader start  */}
              <div className=" relative mb-4 ">
                <ImageUploader setImageFile={setImage} />
                {image ? (
                  <>
                    {isImageLoadingUploading ? (
                      <>
                        {isImageUploaded ? (
                          <>
                            <div className=" absolute right-2 bottom-2  flex  justify-end ">
                              <button
                                disabled
                                className=" rounded-md bg-green-500 py-2 px-4 text-white  focus:outline-none"
                              >
                                {" "}
                                Uploaded{" "}
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className=" absolute right-2 bottom-2  flex  justify-end ">
                              <button className=" rounded-md bg-green-500/40 py-2 px-4 text-white hover:bg-green-600 focus:outline-none">
                                {" "}
                                Uploading...{" "}
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className=" absolute right-2 bottom-2  flex  justify-end ">
                          <button
                            onClick={handleSubmitImage}
                            className=" rounded-md bg-green-500 py-2 px-4 text-white hover:bg-green-600 focus:outline-none"
                          >
                            {" "}
                            Upload{" "}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {err && err.imagedata ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.imagedata}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* image uploader end  */}
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Course Overview start */}
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="WhatWillLearn"
              >
                What Will Learn
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setWhatWillLearn(e);
                }}
              />
              {err && err.WhatWillLearn ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Course Overwiew is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Course Overview end */}

            {/* Course Requirements start */}
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="WhatWillLearn"
              >
                Course Requirement
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setRequirements(e);
                }}
              />
              {err && err.requirements ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Requirement is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Course Requirements end */}

            <div className="lectures_data">
              <div class="mb-4">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="description"
                >
                  Add Chapter
                </label>
                <MultiSelect
                  options={chapterData ? chapterData : ""}
                  value={selectedChapter}
                  onChange={setSelectedChapter}
                  labelledBy="Select"
                />
                {err && err.selectedChapter ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedChapter}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <List type="Chapter" selectedValues={selectedChapter} />
            </div>
            <div className="lectures_data">
              <div class="mb-4 flex items-center"></div>
              <div className=" mb-4 flex gap-5 ">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    checked={checked} // if the checkbox is checked (or not)
                    onChange={function (e) {
                      if (selectedCategories.length == 0) {
                        setChecked(checked);
                        setcheckselectederror("Please select category first");
                      } else {
                        console.log(checked, "checkedchecked");
                        if (checked == false) {
                          setcheckselectederror(null);
                          getChapterdata();
                        } else {
                          setChapterData(perchapterData);
                        }
                        setChecked(!checked);
                      }
                    }} // toggles the checked state
                  />
                  <label
                    for="default-checkbox"
                    class="text-sm font-medium text-gray-900 ms-2 dark:text-gray-300"
                  >
                    Search with same category as course
                  </label>
                  <p className="mb-2 pl-1 pt-1 text-sm text-red-500">
                    {checkselectederror ? checkselectederror : ""}
                  </p>
                </div>
              </div>
              <div class="flex w-full items-end  justify-between ">
                <div className=" w-[50%] pr-3 ">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="description"
                  >
                    Add Question
                  </label>
                  <button
                    onClick={() => {
                      setIsQuwstionAddPopup(!isQuwstionAddPopup);
                    }}
                    className=" w-full rounded-md border border-lightPrimary/40   px-6 py-3 text-lightPrimary hover:bg-lightPrimary hover:text-white  "
                  >
                    {" "}
                    Add{" "}
                  </button>
                  {/* question add popup start ---> */}
                  <div
                    className={` ${
                      isQuwstionAddPopup
                        ? " translate-x-0 "
                        : " translate-x-[100%] "
                    } fixed  top-0 left-0 z-[1000] flex h-screen w-full items-center justify-center bg-white/10 backdrop-blur-xl `}
                  >
                    <div className=" relative h-[80%] w-[90%] rounded-3xl bg-white  ">
                      <DragDropModule
                        setSelectedQuestionss={setSelectedQuestions}
                        quedata={questionsDefaultData}
                      />
                      <button
                        className=" absolute right-4 top-4  rounded-full bg-white "
                        onClick={() => {
                          setIsQuwstionAddPopup(false);
                        }}
                      >
                        {" "}
                        <ImCancelCircle className=" text-4xl " />{" "}
                      </button>
                      <div className=" absolute bottom-3 right-10 flex w-full items-center justify-end">
                        <button
                          onClick={() => {
                            setIsQuwstionAddPopup(false);
                          }}
                          className="rounded-md bg-blueSecondary px-6 py-1 font-semibold text-white"
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative w-[50%] pl-3 ">
                  <button
                    onClick={() => {
                      setIsQuwstionViewPopup(!isQuwstionViewPopup);
                    }}
                    // className=" w-full rounded-md border border-lightPrimary/40   px-6 py-3 text-lightPrimary hover:bg-lightPrimary hover:text-white  "
                    className={`w-full rounded-md border border-lightPrimary/40 px-6 py-3 ${
                      selectedQuestionsCount
                        ? "bg-green-700 text-white"
                        : "bg-white text-lightPrimary"
                    }`}
                  >
                    {" "}
                    <div
                      className={`${
                        selectedQuestionsCount > 0
                          ? "absolute -right-3 -top-4 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 font-bold text-white"
                          : "hidden"
                      }`}
                    >
                      {/* Display count of selected questions */}
                      <p>{selectedQuestionsCount}</p>
                    </div>
                    View selected questions{" "}
                  </button>
                  {/* question add popup start ---> */}
                  <div
                    className={` ${
                      isQuwstionViewPopup
                        ? " translate-x-0 "
                        : " translate-x-[100%] "
                    } fixed   top-0 left-0 z-[1000] flex h-screen w-full items-center justify-center bg-white/10 backdrop-blur-xl `}
                  >
                    <div className=" relative h-[80%] w-[40%]  overflow-y-scroll rounded-3xl bg-white   px-6 pt-14 pb-6  ">
                      {selectedQuestions[0] ? (
                        <>
                          {selectedQuestions.map((value, index) => {
                            return (
                              <>
                                <div className=" my-2 rounded-sm bg-primaryBlue/20 py-2 px-3 ">
                                  <p> {value.text}</p>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className=" flex h-full w-full items-center justify-center text-[40px] font-bold text-gray-300 ">
                            No Questions Selected
                          </div>{" "}
                        </>
                      )}
                    </div>
                    <button
                      className=" absolute right-4 top-4  rounded-full bg-white "
                      onClick={() => {
                        setIsQuwstionViewPopup(false);
                      }}
                    >
                      {" "}
                      <ImCancelCircle className=" text-4xl " />{" "}
                    </button>
                  </div>
                </div>

                <List
                  type="Question"
                  selectedValues={
                    selectedQuestiondata ? selectedQuestiondata : ""
                  }
                />
              </div>
              {err && err.price ? (
                <>
                  {" "}
                  <p className=" pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    {err.price}!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
              <div className=" h-5 w-full "></div>
              {/* <div className=" mb-4 flex gap-5 ">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={checked2} // if the checkbox is checked (or not)
                    onChange={function (e) {
                      if (selectedCategories.length == 0) {
                        setChecked2(checked2)
                        setcheckselectederror2('Please select category first')
                      }
                      else {
                        if (checked2 == false) {
                          setcheckselectederror2(null)
                          getQuestiondata();
                        }
                        else {
                          setoptions(perOptionData)
                        }
                        setChecked2(!checked2)
                      }
                    }}// toggles the checked state 
                  />
                  <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Search with same category as course</label>
                  <p className="mb-2 pl-1 pt-1 text-sm text-red-500">{checkselectederror2 ?
                    checkselectederror2 :
                    ''}</p>
                </div>

              </div> */}
            </div>
            <div className=" mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Price
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="price"
                  type="number"
                  placeholder="Please Enter Price"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
                {err && err.price ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.price}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="price"
                >
                  Sale Price
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="price"
                  type="number"
                  placeholder="Please Enter sale Price"
                  value={sale_price}
                  onChange={(e) => {
                    setSaleprice(e.target.value);
                  }}
                />
                {err && err.sale_price ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {err.sale_price}!
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Important note
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Important note"
                  value={note}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNote(newValue);
                  }}
                />
                {submited && !name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      Note is Required!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* status start  */}
            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="status"
                >
                  Status
                </label>

                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            {/* status end  */}

            {/* is certified start */}
            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="certified"
                >
                  Certified
                </label>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="certified"
                  value={Certified}
                  onChange={(e) => {
                    setCertified(e.target.value);
                  }}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            {/* is certified end */}

            {/* is featured start */}
            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="feature"
                >
                  Feature
                </label>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="feature"
                  value={feature}
                  onChange={(e) => {
                    setFeature(e.target.value);
                  }}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>

            {/* is featured end */}

            {/* Additional fields can be added similarly */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}
              >
                Add Course
              </button>
            </div>
          </div>
        </div>
        {/* /// sidebar */}
        <div className={` relative !h-[660px]  w-[30%] `}>
          <div
            className={`  ${
              isSticky ? "relative !h-fit rounded-md   " : "relative"
            } w-full rounded-md bg-white py-20 px-5`}
          >
            <div className=" sticky  top-0 flex flex-col  gap-3 ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Grade
                </label>
                <Select
                  defaultValue={selectedGrade}
                  // onChange={setSelectedGrade}
                  onChange={handleGradeChange}
                  options={gradeData}
                />
                {err && err.selectedGrade ? (
                  <>
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {err.selectedGrade}!
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Subject
                </label>
                <Select
                  defaultValue={selectedSubject}
                  onChange={setSelectedSubject}
                  options={subjectData}
                />
                {err && err.selectedSubject ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedSubject}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Category
                </label>
                <MultiSelect
                  options={CategoryReData}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  data={selectedCategories}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedCategories ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedCategories}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Tag
                </label>

                <MultiSelect
                  options={TagReData}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedTags ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedTags}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
