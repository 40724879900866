import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  questionGet,
  subTopicGet,
  topicGet,
  gradeGet,
  subjectGet,
  getSingleGradeAllSubjects,
  chapterAdd,
  allactiveQuestion,
  GetCategoryAndSubcategory,
  tagsAllData,
  imageAdd,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./chapter.css";
import List from "../../../components/lecture/List";
import makeAnimated from "react-select/animated";
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import Select from "react-select";
import LoaderComp from "components/common/LoaderComp";
import { useNavigate } from "react-router-dom";

export default function CourseAdd() {
  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [CatSubData, setCatSubData] = useState([]);
  const [TagData, setTagData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imagedata, setImageData] = useState(null);
  const [checkselectederror, setcheckselectederror] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [selectedQuestion, setselectedQuestion] = useState([]);
  const [options, setoptions] = useState([]);
  const [permanentoptions, setpermanentoptions] = useState([]);
  const [optionstopic, setOptionstopic] = useState([
    { topic: [], subtopic: [], questiondata: [] },
  ]);
  //console.log(optionstopic, 888);
  const [selectedTags, setSelectedTags] = useState([]);
  const [checked, setChecked] = useState(false);
  const [topicData, setTopicData] = useState("");
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [selectedSubTopic, setSelectedSubTopic] = useState([]);
  const [slug, setSlug] = useState(null);
  const [subTopicData, setSubTopicData] = useState("");

  const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);

  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState("0");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [gradeData, setGradeData] = useState("");
  const [gradeData2, setGradeData2] = useState("");
  const [gradeSlug, setGradeSlug] = useState(null);
  const [subjectData, setSubjectData] = useState("");

  const handleGradeChange = async (e) => {
    // setSelectedGrade(e);
    setSelectedGrade(e);
    setSelectedSubject(null);
    // setSelectedTopic(null);
    // setSelectedSubTopic(null);
    console.log(e, "handle grade change");
    setSelectedGrade(e);
    console.log(gradeData2, "grade data raw dataaaa");
    console.log(
      gradeData2.find((element) => element.id === e.value).slug,
      "gradeData2[0].namegradeData2[0].name"
    );
    setGradeSlug(gradeData2.find((element) => element.id === e.value).slug);
  };

  const handleImageChange = (e) => {
    const selectedImages = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files,
    };
    setImage(selectedImages);
  };

  const questionDataFetch = async (topicid, value) => {
    await axios
      .post(
        questionGet,
        {
          topicid: topicid,
          subtopicid: value ? value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setoptions(res.data.formattedData);
      })
      .catch((err) => {
        console.log(err);
        setoptions([]);
        //  setSubTopicData(null);
      });
  };

  const subTopicDataFetch = async (value) => {
    await axios
      .post(
        subTopicGet,
        {
          topicid: value ? value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setSubTopicData(res.data.formattedData);
      })
      .catch((err) => {
        console.log(err);
        setSubTopicData(null);
      });
  };
  const handleSubmitImage = async (e) => {
    e.preventDefault();
    // console.log(image.data, 'image111');

    let formData = new FormData();
    formData.append("file", image.data[0]);

    axios
      .post(
        imageAdd,
        formData,

        {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: false,
        }
      )
      .then((res) => {
        if (res.status === 201) {
          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setImageData(res.data.path);
          setErr(null);
          setSubmited(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };

  const topicDataFetch = async () => {
    // console.log(selectedSubject, 'selectedSubjectselectedSubject');
    await axios
      .post(
        topicGet,
        {
          subjectId: selectedSubject?.value ? selectedSubject?.value : 0,
          gradeId: selectedGrade.value ? selectedGrade.value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };
  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }

    setSelectedValues(selected);
  };
  const getAllCategorysData = async () => {
    // await axios.get(allactiveQuestion, {
    //   withCredentials: true
    // }).then(res => {
    //   //console.log(res.data.array,'resres');
    //   setoptions(res.data.array);
    //   setpermanentoptions(res.data.array);
    //   // setChapterData(res.data.data);
    // }).catch(err => {
    //   setoptionsCat(null);
    // })
  };
  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    // Reset parent category when switching between category and subcategory
    setParentCategory(null);
  };

  const [chapterData, setChapterData] = useState(null);
  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
        setGradeData2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  // const subjectDataFetch = async () => {
  //   await axios
  //     .get(subjectGet)
  //     .then((res) => {
  //       setSubjectData(res.data.formatteddata);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSubjectData(null);
  //     });
  // };
  const subjectDataFetch = async () => {
    try {
      const response = await axios.post(
        getSingleGradeAllSubjects,
        { gradeSlug: gradeSlug },
        { withCredentials: true }
      );
      const subjects = response.data.data.subjects;
      console.log(subjects, "single grade subjects");

      // Format subjects for rendering
      const formattedSubjects = subjects.map((subject) => ({
        value: subject.id,
        label: subject.name, // You can format it as needed
      }));

      setSubjectData(formattedSubjects);
    } catch (error) {
      console.error(error);
      setSubjectData([]);
    }
  };

  useEffect(() => {
    // Fetch subject data when selectedGrade changes
    if (selectedGrade) {
      subjectDataFetch(selectedGrade.value);
    }
  }, [selectedGrade]);
  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        chapterAdd,
        {
          name,
          slug,
          description,
          categoryType,
          parentCategory,
          status,
          selectedQuestiondata,
          note,
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
          imagedata,
          selectedTopic,
          selectedSubTopic,
          optionstopic,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Chapter Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() => {
          Router("/admin/chapter");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });

    console.log(selectedValues, "selectedValues");
  };
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };
  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }

  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
    // topicDataFetch();
  }, []);
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);
  useEffect(() => {
    getAllCategorysData();
  }, []);
  // useEffect(() => {
  //   //subTopicDataFetch();
  //   questionDataFetch();
  // }, [optionstopic]);
  useEffect(() => {
    if (selectedQuestion.length > 0) {
      setSelectedQuestiondata(selectedQuestion);
    }
  }, [selectedQuestion]);
  useEffect(() => {
    getAllCategorysData();
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedQuestiondata(selected);
    }
  }, [selected]);
  useEffect(() => {
    topicDataFetch();
  }, [selectedSubject]);
  // useEffect(() => {
  //   questionDataFetch();
  // }, [selectedSubTopic]);
  useEffect(() => {
    if (checked == false) {
      setoptions(permanentoptions);
    }
  }, [checked]);
  const handleInputChange = (index, field, value) => {
    console.log(index, field, value, "valuevaluevaluevalue");
    if (field == "topic") {
      subTopicDataFetch(value.value);
    }

    if (field == "subtopic") {
      questionDataFetch(optionstopic[index]["topic"].value, value.value);
    }
    const newOptions = [...optionstopic];
    newOptions[index][field] = value;
    // console.log(newOptions,'newOptionsnewOptionsnewOptionss');
    setOptionstopic(newOptions);
  };

  // Function to remove a set of input fields
  const removeInputFields = (index) => {
    const updatedFields = [...optionstopic];
    updatedFields.splice(index, 1);
    setOptionstopic(updatedFields);
  };
  //console.log(optionstopic, 'optionstopicoptionstopicoptionstopicoptionstopic');
  const renderInputFields = () => {
    console.log(optionstopic, "optionstopicoptionstopic");
    return optionstopic.map((option, index) => (
      <div key={index}>
        <div className=" border border-blueSecondary px-7  py-4 ">
          <div className="">
            <p className=" pb-1 font-bold text-primaryBlue">
              Topic {index + 1}
            </p>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Topic
                </label>
                <Select
                  defaultValue={optionstopic[index].topic}
                  // onChange={setSelectedTopic}
                  onChange={(e) => handleInputChange(index, "topic", e)}
                  options={topicData ? topicData : []}
                />

                {err && err["topic"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["topic"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Sub Topic
                </label>
                <Select
                  defaultValue={optionstopic[index].subtopic}
                  // onChange={setSelectedTopic}
                  onChange={(e) => handleInputChange(index, "subtopic", e)}
                  options={subTopicData ? subTopicData : []}
                />

                {err && err["topic"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["subtopic"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="  flex ">
            <div className="w-[100%]">
              <div className=" w-full  md:w-1/2 lg:w-full">
                <div class="mb-4">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="description"
                  >
                    Add Question
                  </label>
                  <MultiSelect
                    options={options ? options : ""}
                    value={
                      optionstopic[index].questiondata
                        ? optionstopic[index].questiondata
                        : []
                    }
                    // onChange={setSelected}
                    onChange={(e) =>
                      handleInputChange(index, "questiondata", e)
                    }
                    labelledBy="Select"
                  />
                  <List
                    type="Question"
                    selectedValues={
                      optionstopic[index].questiondata
                        ? optionstopic[index].questiondata
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4"></div>
          {index === 0 ? (
            <></>
          ) : (
            <>
              <div className=" flex w-full items-center justify-end">
                <button
                  className=" rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500 "
                  type="button"
                  onClick={() => removeInputFields(index)}
                >
                  Remove Topic
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Add Chapter
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Chapter Name"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                  Slug
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="slug"
                  type="text"
                  placeholder="chapter-slug"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                />
                {err && err.slug ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.slug}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="lectures_data">
              <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
                {renderInputFields()}
                {err && err["options"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["options"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
                <div
                  className=" mt-3 w-fit rounded-md bg-primaryBlue  px-5 py-2 text-white "
                  onClick={() =>
                    setOptionstopic([
                      ...optionstopic,
                      {
                        topic: [],
                        questiondata: [],
                      },
                    ])
                  }
                >
                  Add Topic
                </div>
                <LoaderComp submited={submited} />
              </div>
              {/* <div class="mb-4">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="description"
                >
                  Add Question
                </label>
                <MultiSelect
                  options={options ? options : ''}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
                <List type='Question' selectedValues={selectedQuestiondata ? selectedQuestiondata : ''} />
              </div> */}
            </div>
            {/* <div>
              < List selectedValues={selectedValues} />
            </div> */}
            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Important note
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Important note"
                  value={note}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNote(newValue);
                  }}
                />
              </div>
            </div>

            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="status"
                >
                  Status
                </label>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            {/* Additional fields can be added similarly */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}
              >
                Add Chapter
              </button>
            </div>
          </div>
        </div>
        <div className={` relative !h-[560px]  w-[30%] overflow-y-scroll `}>
          <div
            className={`  ${
              isSticky
                ? "fixed top-[122px] right-7 !h-fit w-[22.6%] overflow-y-scroll rounded-md "
                : "relative"
            } w-full rounded-md bg-white py-20 px-9`}
          >
            <div className=" sticky  top-0 flex flex-col  gap-3 ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Grade
                </label>
                <Select
                  defaultValue={selectedGrade}
                  // onChange={setSelectedGrade}
                  onChange={handleGradeChange}
                  options={gradeData}
                />
                {err && err.selectedGrade ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedGrade}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Subject
                </label>
                <Select
                  defaultValue={selectedSubject}
                  onChange={(e) => {
                    setSelectedSubject(e);
                  }}
                  options={subjectData}
                />
                {err && err.selectedSubject ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedSubject}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Category
                </label>
                <MultiSelect
                  options={CategoryReData}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  data={selectedCategories}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedCategories ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedCategories}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Tag
                </label>

                <MultiSelect
                  options={TagReData}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedTags ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedTags}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full">
                <div className="mb-4 w-full px-3 md:w-1/2 lg:w-full">
                  <form
                    onSubmit={handleSubmitImage}
                    enctype="multipart/form-data"
                  >
                    <label
                      className="mb-2  block text-sm font-bold"
                      htmlFor="image"
                    >
                      Feature Image
                    </label>

                    {image ? (
                      <img
                        src={image ? image.preview : ""}
                        width="100"
                        height="100"
                      />
                    ) : (
                      ""
                    )}
                    <hr></hr>
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      className="focus:shadow-outline w-full appearance-none rounded   border leading-tight focus:outline-none"
                      id="image"
                      onChange={handleImageChange}
                    />

                    <button
                      class="bg-transparent hover:border-transparent rounded border border-blue-500 py-2 px-4 font-semibold text-blue-700 hover:bg-blue-500 hover:text-white"
                      type="submit"
                    >
                      update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
