const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    user: {}
  };
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        add(state, action) {
            state.user = action.payload;
        },
        remove(state, action) {
            return state.filter((item) => item.id !== action.payload);
        },
    },
});

export const { add, remove } = userSlice.actions;
export default userSlice.reducer;
