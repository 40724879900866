import React from 'react'

export default function LoaderComp({ submited }) {
  return (
    <>

      {
        submited ? (
          <>
            <div className="fixed  w-full  bg-white/80 rounded-lg  backdrop-blur-sm z-[2] top-0 left-0 h-[100%] flex items-center justify-center ">
              <div className="loader-4 flex w-full h-full  items-center justify-center rounded gap-4 ">
                <div className="circle-5"></div>
                <div className="circle-5"></div>
                <div className="circle-5"></div>
                <div className="circle-5"></div>
                <div className="circle-5"></div>
              </div>
            </div>
          </>
        ) : <></>
      }

    </>
  )
}
