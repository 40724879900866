import React, { useState, useEffect } from "react";
import CkEditorComp from "components/common/CkEditorComp";
import { QuestionAddApi, imageCreateAi, imageRemove, imageAdd, courseAllData, gradeGet, topicGet, getSingleGradeAllSubjects, GetCategoryAndSubcategory, tagsAllData } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "components/common/LoaderComp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { FaCheckCircle, FaEye, FaFileUpload, FaImage, FaUpload } from "react-icons/fa";
import Swal from 'sweetalert2';
import { MdDelete } from "react-icons/md";

export default function QuestionAdd({ index, data, grade, subject, topic, category, tag, questionsListToValidate, setQuestionsListToValidate }) {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [name, setName] = useState(null);
    const [slug, setSlug] = useState(null);
    const [err, setErr] = useState();

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [questionContentData, setQuestionContentData] = useState("");
    const [questionAnswer, setQuestionAnswer] = useState("");
    const [CatSubData, setCatSubData] = useState([]);
    const [options, setOptions] = useState([{ name: "", question_id: "", image: "", is_correct: 0, description: "" }]);
    const [status, setStatus] = useState("active");
    const [type, setType] = useState("single");
    const [submited, setSubmited] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [newImageUploading, setNewImageUploading] = useState(0);
    const [isApproved, setIsApproved] = useState(false); // New state for approval status
    const [imageLoading, setImageLoading] = useState(0);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
console.log(options,"setOptions");
    const handleInputChange = (index, field, value) => {
        const newOptions = [...options];
        if (field === "image") {
            let formData = new FormData();
            formData.append("file", value);
            axios.post(imageAdd, formData, {
                headers: { "Content-type": "multipart/form-data" },
            }).then(res => {
                if (res.status === 201) {
                    toast.success("Image uploaded Successfully", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    newOptions[index][field] = res.data.path;
                    setOptions(newOptions);
                }
            });
        } 
        else if(field === "is_correct") {
            newOptions[index][field] = Number(value);
            setOptions(newOptions);
        }
        else {
            newOptions[index][field] = value;
            setOptions(newOptions);
        }
    };

    let newerrors = {};

    const handleRegenerate = async (e) => {
        e.preventDefault();
        setImageLoading(1);
        if (!prompt) {
            setImageLoading(0);
            newerrors.prompt = "prompt is required!";
            setErr(newerrors);
            return;
        }
        else {
            await axios.post(imageCreateAi, { prompt }).then((res) => {
                setImageLoading(2);
                setQuestionsListToValidate((preData) =>
                    preData.map((data) =>
                        data.id === index ? { ...data, image_url: res.data.url } : data
                    )
                );
            }).catch((err) => {
                Swal.fire('Error!', 'There was an error while Generating the Image.', 'error');
                setImageLoading(0);
            });
        }


    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this image?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (result.isConfirmed) {
            await axios.post(imageRemove, { image_url: questionsListToValidate[index].image_url }).then((res) => {
                Swal.fire('Deleted!', 'Your image has been deleted.', 'success');
                setQuestionsListToValidate((prevImages) =>
                    prevImages.map((image) =>
                        image.id === index ? { ...image, image_url: null } : image
                    )
                );
            }).catch((err) => {
                Swal.fire('Error!', 'There was an error deleting your image.', 'error');
            });
        }
    };

    const handleReplace = async (e) => {
        e.preventDefault();
        setNewImageUploading(1);
        try {
            let formData = new FormData();
            formData.append("file", selectedImage);
            axios.post(imageAdd, formData, {
                headers: { "Content-type": "multipart/form-data" },
            }).then(res => {
                if (res.status === 201) {
                    toast.success("Image uploaded Successfully", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setNewImage(res.data.path);
                    setNewImageUploading(2);
                    setQuestionsListToValidate((prevImages) =>
                        prevImages.map((image) =>
                            image.id === index ? { ...image, image_url: res.data.path } : image
                        )
                    );
                }
            });
        } catch (error) {
            setNewImageUploading(0);
            toast.error("Error while uploading Image !", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleShow = (e) => {
        e.preventDefault();
        console.log('Show Image');
    };

    const removeInputFields = (index) => {
        const updatedFields = [...options];
        updatedFields.splice(index, 1);
        setOptions(updatedFields);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmited(true);

        try {
            if (!questionsListToValidate[index].isApproved) {
                toast.error("Approve the form first ", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSubmited(false);
            }
            else {
                await axios.post(QuestionAddApi, {
                    questions: name,
                    slug: slug,
                    status: status,
                    type: type,
                    content: questionContentData,
                    options: options,
                    grade: grade,
                    subject: subject,
                    topic: topic,
                    categories: category,
                    tags: tag,
                    questionanswer: questionAnswer,
                    answerdata: questionAnswer,
                }, { withCredentials: true }).then((res) => {
                    toast.success("Question Created Successfully", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    console.log(res, "resresresresres");
                    setErr(null);
                    setQuestionsListToValidate((preQuestions) =>
                        preQuestions.map((question) =>
                            question.id === index ? { ...question, isSubmited: true } : question
                        )
                    );
                    setQuestionsListToValidate((preQuestions) =>
                        preQuestions.map((question) =>
                            question.id === index ? { ...question, saved_id: res.data.id } : question

                        )
                    );
                    setSubmited(false);

                })

            }

            // setTimeout(() => {
            //     navigate("/admin/questions");
            // }, 3000);
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setErr(err.response.data.errors);
            } else if (err.response && err.response.status === 500) {
                toast.error("Something Went Wrong!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setErr(null);
            }
            setSubmited(false);
        }
    };

    useEffect(() => {
        if (data) {
            setName(data.questionName);
            setSlug(data.questionName);
            setQuestionContentData(data.content);
            setQuestionAnswer(data.answer);
            setPrompt(data.imagePrompt);
            const optionObject = data.options.map(value => ({
                name: value.name,
                is_correct: value.is_correct,
                description: "non",
                image: "",
            }));
            setOptions(optionObject);
        }
    }, [data]);

    const renderInputFields = () => {
        return options.map((option, index) => (
            <div key={index} className="border border-blueSecondary px-7 py-4">
                <p className="pb-1 font-bold text-primaryBlue">Option {index + 1}</p>
                <div className="flex w-full md:w-1/2 lg:w-full">
                    <input
                        className="focus:shadow-outline w-full appearance-none rounded border leading-tight focus:outline-none"
                        id="name"
                        type="text"
                        placeholder="Option Name"
                        value={option.name || ""}
                        onChange={(e) => handleInputChange(index, "name", e.target.value)}
                    />
                    {err && err[`options[${index}].name`] && (
                        <p className="pl-1 pt-1 text-sm text-red-500">{err[`options[${index}].name`]}</p>
                    )}
                </div>
                <div className="flex w-full">
                    <select
                        className="focus:shadow-outline w-full appearance-none rounded border leading-tight focus:outline-none"
                        id="is_correct"
                        name="is_correct"
                        value={option.is_correct || "0"}
                        onChange={(e) => handleInputChange(index, "is_correct", e.target.value)}
                    >
                        <option value="0">False</option>
                        <option value="1">True</option>

                    </select>
                    <input
                        className="focus:shadow-outline w-full appearance-none rounded border bg-white !py-[10px] leading-tight focus:outline-none"
                        id="name"
                        type="file"
                        onChange={(e) => handleInputChange(index, "image", e.target.files[0])}
                    />
                </div>
                <textarea
                    className="focus:shadow-outline h-12 w-full appearance-none rounded border leading-tight focus:outline-none"
                    id="description"
                    placeholder="Option Description"
                    rows="3"
                    value={option.description || ""}
                    onChange={(e) => handleInputChange(index, "description", e.target.value)}
                ></textarea>
                {err && err[`options[${index}].description`] && (
                    <p className="pl-1 pt-1 text-sm text-red-500">{err[`options[${index}].description`]}</p>
                )}
                {index !== 0 && (
                    <div className="flex w-full items-center justify-end">
                        <button
                            className="rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500"
                            type="button"
                            onClick={() => removeInputFields(index)}
                        >
                            Remove Set
                        </button>
                    </div>
                )}
            </div>
        ));
    };

    let isValid = true;
    let errors = {};
    let correctOptionCount = 0;

    const handleApprove = () => {
        // Perform validation checks
        // Check if the name is not empty
        if (!name || name.trim() === "") {
            isValid = false;
            errors.name = "Question name is required.";
        }

        if (!slug || slug.trim() === "") {
            isValid = false;
            errors.slug = "Question slug is required.";
        }

        // Check if the options are filled correctly
        options.forEach((option, index) => {
            if (!option.name || option.name.trim() === "") {
                isValid = false;
                errors[`options[${index}].name`] = `Option ${index + 1} name is required.`;
            }

            if (option.is_correct === 1) {
                correctOptionCount++;
            };
        });

        if (correctOptionCount === 0) {
            isValid = false;
            errors['optionCorrectionErr'] = 'At least one option must be marked as correct.';
        }
        if (correctOptionCount > 1) {
            if (type === 'single') {
                isValid = false;
                errors['multioptionChoosen'] = 'Only one option should be Selected!';

            }
        }

        // Check if the content and answer are not empty
        if (!questionContentData || questionContentData.trim() === "") {
            isValid = false;
            errors.content = "Content is required.";
        }
        if (!questionAnswer || questionAnswer.trim() === "") {
            isValid = false;
            errors.questionanswer = "Answer is required.";
        }

        // Set the errors
        setErr(errors);
        if (isValid) {
            setIsApproved(true);
            setQuestionsListToValidate((preQuestions) =>
                preQuestions.map((question) =>
                    question.id === index ? { ...question, isApproved: true } : question
                )
            );
            toast.success("All fields are valid. Question approved!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            setIsApproved(false);
            toast.error("Please fill in all required fields correctly.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <div className="mx-auto flex w-full border-2 border-gray-200 gap-4 py-2">
                <div className="h-full w-full rounded-md bg-white px-4">
                    <div >
                        <div className="text-2xl font-bold text-blueSecondary mb-4">Question {index + 1}</div>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-bold mb-2" htmlFor="name">Question Name</label>
                                <textarea
                                    className="w-full rounded border focus:shadow-outline focus:outline-none"
                                    id="name"
                                    type="text"
                                    placeholder="Question title"
                                    value={name}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        setName(newValue);
                                        const newSlug = newValue.toLowerCase().replace(/\s+/g, "-").replace(/[^a-z0-9-]/g, "");
                                        setSlug(newSlug);
                                    }}
                                />
                                {err && err.questions && <p className="text-sm text-red-500 pt-1">{err.questions}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-bold mb-2" htmlFor="slug">Slug</label>
                                <textarea
                                    className="w-full rounded border focus:shadow-outline focus:outline-none"
                                    id="slug"
                                    type="text"
                                    placeholder="question-slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                />
                                {err && err.slug && <p className="text-sm text-red-500 pt-1">{err.slug}</p>}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div>
                                <label className="block text-sm font-bold mb-2" htmlFor="status">Type</label>
                                <select
                                    className="w-full rounded border focus:shadow-outline focus:outline-none"
                                    id="type"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="single">Single</option>
                                    <option value="multiple">Multiple</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-bold mb-2" htmlFor="status">Status</label>
                                <select
                                    className="w-full rounded border focus:shadow-outline focus:outline-none"
                                    id="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="mt-4">
                            <div className="flex flex-col items-center w-full py-4">
                                <div className="flex w-full mb-4">
                                    <div className="flex flex-col items-center justify-center w-1/2 border border-gray-300 rounded-lg p-4">
                                        {questionsListToValidate[index].image_url ? (
                                            <img src={questionsListToValidate[index].image_url} alt="Image Preview" className="max-w-full h-full mb-4 rounded-lg" />
                                        ) : (
                                            <p className="text-lg">No Image</p>
                                        )}
                                    </div>
                                    <div className="flex flex-col ml-4 w-1/2">
                                        <textarea
                                            className="border border-gray-300 text-gray-400 rounded-lg p-2  h-32"
                                            placeholder="Enter prompt here..."
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                        ></textarea>

                                        {err && err.prompt && <p className="text-sm text-red-500 ">Prompt is Required!</p>}
                                        <span className=" mb-4" ></span>
                                        <button
                                            disabled={imageLoading === 1 ? true : imageLoading === 2 ? true : false}
                                            className={` ${imageLoading === 0 ? "bg-blue-500" : imageLoading === 1 ? "bg-blue-500/30" : imageLoading === 2 ? "!bg-green-300" : " "} text-white px-4 py-2 rounded-lg`} onClick={handleRegenerate}>
                                            Generate Image with AI
                                        </button>
                                        <div className="flex justify-between w-full gap-3 mt-4">
                                            <label htmlFor='select-input-image' className="bg-green-500 flex items-center justify-center gap-2 w-full text-white px-4 py-2 rounded-lg">
                                                <FaImage className=" text-xl " />  Select
                                            </label>
                                            <input className="hidden" id="select-input-image" onChange={handleImageChange} type="file" accept="image/*" />
                                            <button className="bg-red-500 text-white flex items-center justify-center gap-2 w-full px-4 py-2 rounded-lg" onClick={handleDelete}>
                                                < MdDelete className=" text-xl " />  Delete
                                            </button>
                                        </div>
                                        <div>
                                            {selectedImage && (
                                                <>
                                                    <p className="h-5 mt-2 w-full overflow-hidden">
                                                        {`name:  ${selectedImage.name}`}
                                                    </p>
                                                    <div className="flex items-center mt-2 gap-3">
                                                        <button onClick={handleReplace} className="bg-green-500 flex items-center justify-center gap-2 w-full text-white px-4 py-2 rounded-lg">
                                                            <FaUpload className=" text-xl " />  Upload
                                                        </button>
                                                        <button className="bg-gray-500 flex items-center justify-center gap-2 w-full text-white px-4 py-2 h-fit rounded-lg" onClick={handleShow}>
                                                            <FaEye className=" text-xl " />  View
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-4">
                            <p className="text-sm font-bold text-primaryBlue mb-2">Content</p>
                            <CkEditorComp predata={questionContentData || ''} handleData={(e) => setQuestionContentData(e)} />
                            {err && err.content && <p className="text-sm text-red-500 mt-1">Content is Required!</p>}
                        </div>
                        <div className="mt-4">
                            <p className="text-sm font-bold text-primaryBlue mb-2">Answer</p>
                            <CkEditorComp predata={questionAnswer || ""} handleData={(e) => setQuestionAnswer(e)} />
                            {err && err.questionanswer && <p className="text-sm text-red-500 mt-1">{err.questionanswer}</p>}
                        </div>
                        <div className="mt-4">

                            {renderInputFields()}
                            {err && err.options && <p className="text-sm text-red-500 mt-1">{err.options}</p>}
                            <button
                                disabled={options.length >= 4}
                                type="button"
                                className="mt-1 text-[10px] border-primaryBlue border text-primaryBlue px-4 py-1 rounded"
                                onClick={() => setOptions([...options, { name: "", question_id: "", image: "", is_correct: "", description: "" }])}
                            >
                                Add Set
                            </button>
                        </div>
                        <LoaderComp submited={submited} />
                        <div className="flex items-center justify-center gap-5">
                            <button onClick={handleApprove} disabled={questionsListToValidate[index].isApproved} className={`mt-4  ${questionsListToValidate[index].isApproved ? 'bg-[#FFC96F]' : ' bg-[#FFA62F]'}  text-white w-1/5 px-4 flex items-center justify-center py-2 rounded`}> {questionsListToValidate[index].isApproved ? <FaCheckCircle className=" text-2xl " /> : 'Approve'} </button>
                            <button disabled={questionsListToValidate[index].isSubmited || !questionsListToValidate[index].isApproved} onClick={handleSubmit} className="mt-4 bg-primaryBlue text-white px-4 py-2 w-1/5 rounded">Submit </button>
                        </div>
                        <div className=" w-full flex justify-center ">
                            {
                                err && err.optionCorrectionErr && <p className="text-sm text-red-500 mt-1"> {err.optionCorrectionErr} </p>
                            }
                            {
                                err && err.multioptionChoosen && <p className="text-sm text-red-500 mt-1"> {err.multioptionChoosen} </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
