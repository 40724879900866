import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserDataTable } from ".//userData";
import ComplexTable from "views/admin/default/components/ComplexTable";
import { getAllUserList } from "proxyUrl";
import axios from "axios";
import { useSelector } from 'react-redux';
export default function User() {

  const [user, setUser] = useState("");
  const CurrentUserDetails = useSelector(state => state.user);
  const fetchData = () => {
    axios
      .post(
        getAllUserList,
        {
       id:CurrentUserDetails.user.userInfo.user_id
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        return res;
      })
      .then((userdata) => {
        const userData = userdata.data;
        setUser(userData);
      });
  };
  useEffect(() => {
    fetchData();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className=" add-booking-header  relative overflow-hidden  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 py-3 shadow-lg dark:bg-navy-800">
      {/* <div className="w-1 h-full bg-primaryBlue absolute top-0 left-0 "></div> */}
      {/* <div className="w-1 h-full bg-primaryBlue absolute top-0 right-0 "></div> */}
        <div className="text-xl font-bold text-white dark:text-white">
          All Users
        </div>
        <Link to={`/admin/users/add`}>
          <button className="rounded-full bg-white p-1  px-3 text-navy-900 dark:bg-navy-900 dark:text-white">
            Add Users
          </button>
        </Link>
      </div>
      <div className="relative -mt-7  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        {user ? (
          <ComplexTable
            columnsData={UserDataTable}
            editRoute={`/admin/users/edit`}
            deleteRoute={`/admin/users/delete`}
            columnLenghth={user.length}
            tableData={user}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
