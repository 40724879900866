import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/Sidebar";
// import Footer from "components/footer/Footer";
import { useSelector } from "react-redux";
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
// import DataTables from "views/admin/tables";

import SignIn from "views/auth/SignIn";

import UserAdd from "views/admin/user/UserAdd";
import User from "views/admin/user/User";
import UserEdit from "views/admin/user/UserEdit";
import UserDelete from "views/admin/user/UserDelete";

import Category from "views/components/Category/index";
import CategoryAdd from "views/components/Category/CategoryAdd";
import CategoryEdit from "views/components/Category/CategoryEdit";

import Course from "views/components/Courses/index";
import CourseAdd from "views/components/Courses/CourseAdd";
import CourseEdit from "views/components/Courses/CourseEdit";
import CourseShow from "views/components/Courses/CourseShow";

import Test from "views/components/Test/index";
import TestAdd from "views/components/Test/TestAdd";
import TestEdit from "views/components/Test/TestEdit";
import TestShow from "views/components/Test/TestShow";
import TestAddAi from "views/components/Test/TestAi";

import Content from "views/components/Content/index";
import ContentAdd from "views/components/Content/ContentAdd";
import ContentEdit from "views/components/Content/ContentEdit";
import ContentShow from "views/components/Content/ContentShow";

import Chapter from "views/components/Chapter/index";
import ChapterAdd from "views/components/Chapter/ChapterAdd";
import ChapterEdit from "views/components/Chapter/ChapterEdit";
import ChapterShow from "views/components/Chapter/ChapterShow";

import Questions from "views/components/Question/index";
import QuestionAdd from "views/components/Question/QuestionAdd";
import QuestionEdit from "views/components/Question/QuestionEdit";
import QuestionShow from "views/components/Question/QuestionShow";

import Tags from "views/components/Tags/index";
import TagAdd from "views/components/Tags/TagAdd";
import TagEdit from "views/components/Tags/TagEdit";

import TopicEdit from "views/components/Topic/TopicEdit";
import TopicShow from "views/components/Topic/TopicShow";
import TopicAdd from "views/components/Topic/TopicAdd";

// Icon Imports
import { MdHome, MdPerson, MdOutlineTopic, MdCategory } from "react-icons/md";
import { FaUserFriends, FaTags } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import SignOut from "views/auth/SignOut";
import { getUserDataByToken } from "proxyUrl";
import ErrorPaheInner from "views/ErrorPaheInner";
import axios from "axios";

export default function Admin(props) {
  const [permission, setPermission] = useState();
  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setPermission(res.data.user.userPermission);
        return res;
      });
  };

  const routesLinks = [
    {
      name: "Dashboard",
      layout: `/admin`,
      path: "dashboard",
      icon: <MdHome className="h-6 w-6" />,
      display: "",
      component: <MainDashboard />,
    },
  ];
  useEffect(() => {
    userDataByApi();
  }, []);
  // generate routes ***************************

  const logged_user_info = useSelector((state) => state.user.user);
  if (!logged_user_info.userPermission) {
  }
  const userPermissions = logged_user_info.userPermission
    ? logged_user_info.userPermission
    : permission;
  if (userPermissions) {
    console.log(userPermissions, "userPermissionsuserPermissions");
    userPermissions.forEach((element) => {
      if (element === "questionsView") {
        routesLinks.push({
          name: "Questions",
          layout: `/${`admin`}`,
          icon: <BsFillPatchQuestionFill className="h-6 w-6" />,
          path: "questions",
          display: "",
          component: <Questions />,
        });
      } else if (element === "questionAdd") {
        routesLinks.push({
          name: "Add Question",
          layout: `/${`admin`}`,
          path: "questions/add",
          display: "hidden",
          icon: "",
          component: <QuestionAdd />,
        });
      } else if (element === "questionEdit") {
        routesLinks.push({
          name: "Edit Question",
          layout: `/${`admin`}`,
          path: "questions/edit/:id",
          display: "hidden",
          icon: "",
          component: <QuestionEdit />,
        });
      } else if (element === "topic") {
        routesLinks.push({
          name: "Topic",
          layout: `/${`admin`}`,
          path: "topic",
          display: "",
          icon: <MdOutlineTopic className="h-6 w-6" />,
          component: <TopicShow />,
        });
        routesLinks.push({
          name: "Topic Add",
          layout: `/${`admin`}`,
          path: "topic/add",
          display: "hidden",
          icon: <MdOutlineTopic className="h-6 w-6" />,
          component: <TopicAdd />,
        });
        routesLinks.push({
          name: "Topic Edit",
          layout: `/${`admin`}`,
          path: "topic/edit/:id",
          display: "hidden",
          icon: <MdOutlineTopic className="h-6 w-6" />,
          component: <TopicEdit />,
        });
      } else if (element === "questionShow") {
        routesLinks.push({
          name: "Show Question",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "question/show/:id",
          display: "hidden",
          component: <QuestionShow />,
        });
      } else if (element === "testView") {
        routesLinks.push({
          name: "Test",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "test",
          display: "",
          component: <Test />,
        });
      } else if (element === "testAdd") {
        routesLinks.push({
          name: "Add test",
          layout: `/${`admin`}`,
          path: "test/add",
          display: "hidden",
          icon: "",
          component: <TestAdd />,
        },
      {
        name: "Create Test With Ai",
        layout:`/admin`,
        path: "test/addwithai",
        display: "hidden",
        icon : "",
        component: <TestAddAi/>,

      });
      } else if (element === "testEdit") {
        routesLinks.push({
          name: "Edit Test",
          layout: `/${`admin`}`,
          path: "test/edit/:id",
          display: "hidden",
          icon: "",
          component: <TestEdit />,
        });
      } else if (element === "testShow") {
        routesLinks.push({
          name: "Show Test",
          layout: `/${`admin`}`,
          path: "test/show/:id",
          display: "hidden",
          icon: "",
          component: <TestShow />,
        });
      } else if (element === "testView") {
        routesLinks.push({
          name: "Test",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "test",
          display: "",
          component: <Test />,
        });
      } else if (element === "courseView") {
        routesLinks.push({
          name: "Courses",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "course",
          display: "hidden",
          component: <Course />,
        });
      } else if (element === "courseAdd") {
        routesLinks.push({
          name: "Add Course",
          layout: `/${`admin`}`,
          path: "course/add",
          display: "hidden",
          icon: "",
          component: <CourseAdd />,
        });
      } else if (element === "courseEdit") {
        routesLinks.push({
          name: "Edit Course",
          layout: `/${`admin`}`,
          path: "course/edit/:id",
          display: "hidden",
          icon: "",
          component: <CourseEdit />,
        });
      } else if (element === "courseShow") {
        routesLinks.push({
          name: "Show Course",
          layout: `/${`admin`}`,
          path: "course/show/:id",
          display: "hidden",
          icon: "",
          component: <CourseShow />,
        });
      } else if (element === "courseView") {
        routesLinks.push({
          name: "Courses",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "course",
          display: "hidden",
          component: <Course />,
        });
      } else if (element === "contentAdd") {
        routesLinks.push({
          name: "Add Content",
          layout: `/${`admin`}`,
          path: "content/add",
          display: "hidden",
          icon: "",
          component: <ContentAdd />,
        });
      } else if (element === "contentEdit") {
        routesLinks.push({
          name: "Edit Content",
          layout: `/${`admin`}`,
          path: "content/edit/:id",
          display: "hidden",
          icon: "",
          component: <ContentEdit />,
        });
      } else if (element === "contentShow") {
        routesLinks.push({
          name: "Show COntent",
          layout: `/${`admin`}`,
          path: "content/show/:id",
          display: "hidden",
          icon: "",
          component: <ContentShow />,
        });
      } else if (element === "contentView") {
        routesLinks.push({
          name: "Content",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "content",
          display: "",
          component: <Content />,
        });
      } else if (element === "chapterView") {
        routesLinks.push({
          name: "Chapter",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "chapter",
          display: "hidden",
          component: <Chapter />,
        });
      } else if (element === "chapterAdd") {
        routesLinks.push({
          name: "Add Chapter",
          layout: `/${`admin`}`,
          path: "chapter/add",
          display: "hidden",
          icon: "",
          component: <ChapterAdd />,
        });
      } else if (element === "chapterEdit") {
        routesLinks.push({
          name: "Edit Chapter",
          layout: `/${`admin`}`,
          path: "chapter/edit/:id",
          display: "hidden",
          icon: "",
          component: <ChapterEdit />,
        });
      } else if (element === "chapterShow") {
        routesLinks.push({
          name: "Show Chapter",
          layout: `/${`admin`}`,
          path: "chapter/show/:id",
          display: "hidden",
          icon: "",
          component: <ChapterShow />,
        });
      } else if (element === "categoryView") {
        routesLinks.push({
          name: "Categories",
          layout: `/${`admin`}`,
          icon: <MdCategory className="h-6 w-6" />,
          path: "categories",
          display: "",
          component: <Category />,
        });
      } else if (element === "categoryAdd") {
        routesLinks.push({
          name: "Add Category",
          layout: `/${`admin`}`,
          path: "categories/add",
          display: "hidden",
          icon: "",
          component: <CategoryAdd />,
        });
      } else if (element === "categoryEdit") {
        routesLinks.push({
          name: "Edit Category",
          layout: `/${`admin`}`,
          path: "categories/edit/:id",
          display: "hidden",
          icon: "",
          component: <CategoryEdit />,
        });
      } else if (element === "userEdit") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/edit/:id",
          display: "hidden",
          icon: "",
          component: <UserEdit />,
        });
      } else if (element === "userAdd") {
        routesLinks.push(
          {
            name: "Users",
            layout: `/${`admin`}`,
            icon: <FaUserFriends className="h-6 w-6" />,
            path: "users",
            display: "",
            component: <User />,
          },
          {
            name: "add user",
            layout: `/${`admin`}`,
            path: "users/add",
            display: "hidden",
            icon: "",
            component: <UserAdd />,
          }
        );
      } else if (element === "userEdit") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/edit/:id",
          display: "hidden",
          icon: "",
          component: <UserEdit />,
        });
      } else if (element === "userDelete") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/delete/:id",
          display: "hidden",
          icon: "",
          component: <UserDelete />,
        });
      }
    });
  }
  routesLinks.push(
    {
      name: "Profile",
      layout: `/${`admin`}`,
      path: "profile",
      display: "hidden",
      icon: <MdPerson className="h-6 w-6" />,
      component: <Profile />,
    },
    {
      name: "Log In",
      layout: "/auth",
      path: "sign-in",
      display: "hidden",
      icon: <AiOutlineLogout className="h-6 w-6" />,
      component: <SignIn />,
    },
    {
      name: "Tags",
      layout: "/admin",
      path: "tag",
      display: "",
      icon: <FaTags className="h-6 w-6" />,
      component: <Tags />,
    },
    {
      name: " Add Tag",
      layout: "/admin",
      path: "tag/add",
      display: "hidden",
      icon: <FaTags className="h-6 w-6" />,
      component: <TagAdd />,
    },
    {
      name: " Add Tag",
      layout: "/admin",
      path: "tag/edit/:id",
      display: "hidden",
      icon: <FaTags className="h-6 w-6" />,
      component: <TagEdit />,
    },
    {
      name: "Log Out",
      layout: "/auth",
      path: "sign-out",
      display: "",
      icon: <AiOutlineLogout className="h-6 w-6" />,
      component: <SignOut />,
    }
  );

  // generate routes ***************************
  const { ...rest } = props;
  const [open, setOpen] = useState(true);

  const [currentRoute, setCurrentRoute] = useState("Dashboard");

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getActiveRoute(routesLinks);
  });

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  return (
    <div className=" flex w-full     ">
      {/* <div className="w-full h-9 z-30 bg-navy-600 fixed"></div> */}
      <Sidebar
        open={open}
        routes={routesLinks}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="h-screen w-full  ">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={" Creed Infotech"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routesLinks)}
              {...rest}
            />
            <div className="   full mx-auto  h-full p-2 pt-5 md:pr-2">
              <Routes>
                {routesLinks.map((prop, key) => {
                  return (
                    <Route
                      path={`/${prop.path}`}
                      element={prop.component}
                      key={key}
                    />
                  );
                })}
                <Route
                  path="/auth/sign-in"
                  element={<navigate to="/admin/Dashboard" replace />}
                />
                <Route path="/*" element={<ErrorPaheInner />} />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
