import React, { useState, useEffect } from "react";
import axios from "axios";
import { imageAdd, categoryAdd, GetPerentCat } from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./category.css";
import LoaderComp from "components/common/LoaderComp";
import { useNavigate } from "react-router-dom";
import ImageUploader from "components/common/ImageUploader";

export default function CategoryAdd() {
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [imagedata, setImageData] = useState(null);
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState("0");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [PerentCatDataFromApi, setPerentCatDataFromApi] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageLoadingUploading, setIsImageLoadingUploading] = useState(false);
  const handleSubmitImage = async (e) => {
    e.preventDefault();
    setIsImageLoadingUploading(true);
    let formData = new FormData();
    formData.append("file", image);

    axios
      .post(imageAdd, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((res) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(true);
        if (res.status === 201) {
          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(res.data.path, "uploded imgae data");
          setImageData(res.data.path);
          setIsImageUploaded(true);
          setErr(null);
        }
      })
      .catch((err) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(false);
        if (err.response.status === 400) {
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };
  const getAllPerentCatData = async () => {
    axios
      .get(GetPerentCat)
      .then((res) => {
        setPerentCatDataFromApi(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setPerentCatDataFromApi(null);
      });
  };

  useEffect(() => {
    getAllPerentCatData();
  }, []);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    // You can perform additional checks or validations here if needed
    setImage(selectedImage);
  };
  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    // Reset parent category when switching between category and subcategory
    setParentCategory(null);
  };

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        categoryAdd,
        {
          name,
          slug,
          description,
          categoryType,
          parentCategory,
          status,
          imagedata,
          checked,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("category Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() => {
          navigate("/admin/categories");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };

  return (
    <>
      <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg  p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
          Add Category
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="name"
              type="text"
              placeholder="Category Name"
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                setName(newValue);

                // Automatically generate slug based on the name
                const newSlug = newValue
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, "-") // Replace whitespace with hyphen
                  .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                setSlug(newSlug);
              }}
            />
            {err && err.name ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.name}{" "}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="slug"
              type="text"
              placeholder="category-slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            />
            {err && err.slug ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.slug}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
            id="description"
            placeholder="Category Description"
            rows="3"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></textarea>
          {err && err.description ? (
            <>
              {" "}
              <p className=" pl-1 pt-1 text-sm text-red-500">
                {" "}
                Description is Required!
              </p>{" "}
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="mb-4 flex  gap-5">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Status
            </label>
            <select
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4 w-full md:w-1/2 lg:w-full">
            <label
              className="mb-2 block text-sm font-bold "
              htmlFor="categoryType"
            >
              Category Type
            </label>
            <select
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="categoryType"
              value={categoryType}
              onChange={handleCategoryTypeChange}
            >
              <option value={"0"}>Category</option>
              <option value={"1"}>Subcategory</option>
            </select>
          </div>
          {categoryType === "1" && (
            <div className="mb-4 w-full  md:w-1/2 lg:w-full">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="parentCategory"
              >
                Parent Category
              </label>
              <select
                className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                id="parentCategory"
                value={parentCategory}
                onChange={(e) => setParentCategory(e.target.value)}
              >
                {/* Add options for parent categories */}
                <option value="">Select</option>
                {PerentCatDataFromApi ? (
                  <>
                    {PerentCatDataFromApi.map((value, index) => {
                      return (
                        <>
                          <option value={value.id}> {value.name} </option>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    <option value="">Parent Category 1</option>
                    <option value="">Parent Category 2</option>
                  </>
                )}
              </select>
              {err && err.categoryType === "1" && !parentCategory ? (
                <>
                  {" "}
                  <p className=" pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Parent Categor is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="mb-4 flex gap-5">
          <div className=" mb-4 flex gap-5 ">
            <div className="mb-4 w-full  md:w-1/2 lg:w-full">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                checked={checked} // if the checkbox is checked (or not)
                onChange={function (e) {
                  setChecked(!checked);
                }} // toggles the checked state
              />
              <label
                for="default-checkbox"
                class="text-sm font-medium text-gray-900 ms-2 dark:text-gray-300"
              >
                Is Feature
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4 flex gap-5">
          <div className=" relative mb-4 ">
            <ImageUploader setImageFile={setImage} />
            {image ? (
              <>
                {isImageLoadingUploading ? (
                  <>
                    {isImageUploaded ? (
                      <>
                        <div className=" absolute right-2 bottom-2  flex  justify-end ">
                          <button
                            disabled
                            className=" rounded-md bg-green-500 py-2 px-4 text-white  focus:outline-none"
                          >
                            {" "}
                            Uploaded{" "}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" absolute right-2 bottom-2  flex  justify-end ">
                          <button className=" rounded-md bg-green-500/40 py-2 px-4 text-white hover:bg-green-600 focus:outline-none">
                            {" "}
                            Uploading...{" "}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className=" absolute right-2 bottom-2  flex  justify-end ">
                      <button
                        onClick={handleSubmitImage}
                        className=" rounded-md bg-green-500 py-2 px-4 text-white hover:bg-green-600 focus:outline-none"
                      >
                        {" "}
                        Upload{" "}
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {err && err.imagedata ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.imagedata}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Additional fields can be added similarly */}

        <div className="flex justify-end">
          <button
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
            type="submit"
            onClick={handleSubmit}
          >
            Add Category
          </button>
        </div>
        <LoaderComp submited={submited} />
      </div>

      <ToastContainer />
    </>
  );
}
