import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./chapter.css";
import { chapterShow } from "../../../proxyUrl";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import List from "../../../components/lecture/List";

export default function CourseShow() {
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [err, setErr] = useState();
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState('0');
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const Navigate = useNavigate();
  const [catDataFiroApi, setCatDataFromApi] = useState(null);

  // Get ID from URL
  const params = useParams();
  const { id } = params;

  const getChapterDataId = async () => {

    await axios.get(`${chapterShow}/${id}`, {
      withCredentials: true
    }).then(res => {
      console.log(res.data, 'ddddddddd')
      setCourseData(res.data);
    }).catch(err => {
      setCourseData(null);
    })

  }


  useEffect(() => {
    getChapterDataId();
  }, [id]);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
         setIsSticky(true);
      } else {
         setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
                  <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
      <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
          Chapter
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>

            <p>{courseData ? courseData.CatData.name : ''}</p>
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <p>{courseData ? `/${courseData.CatData.slug}` : ''}`</p>
          </div>

        </div>

        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Description
          </label>
          <div dangerouslySetInnerHTML={{__html:courseData ? courseData.CatData.description : ''}} />

        </div>


        <div className="lectures_data" >
          <div class="mb-4">
            <label
              className="mb-2 block text-sm font-bold "
              htmlFor="description"
            >
              Content
            </label>

          </div>
        </div>
        <div>
          {courseData ?
            courseData.chaptertopic.chapter.map((topicdata) => (
              <>
                <div >
                      <div className=" border border-blueSecondary px-7  py-4 ">
                        <div className="">
                          <p className=" pb-1 font-bold text-primaryBlue">
                            Topic
                          </p>
                        </div>
                        <div className=" flex w-full  md:w-1/2 lg:w-full">
                          <div class="w-[100%] ">
                            <div className="mb-0 w-full ">
                              {/* <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-600"
                          >
                            Topic
                          </label> */}
                              {topicdata.topic?.name}
                            </div>

                          </div>
                        </div>
                        <div className="  flex ">
                          <div className="w-[100%]">
                            <div className=" w-full  md:w-1/2 lg:w-full">
                              <div class="mb-4">
                                <label
                                  className="mb-2 block text-sm font-bold "
                                  htmlFor="description"
                                >
                                  Question
                                </label>
                                <ul className="divide-y divide-slate-100">
                                  <div class="block w-full overflow-x-auto">
                                    <table class="items-center bg-transparent w-full border-collapse ">

                                      <tbody>

                                        {topicdata.topics.map((questions, i) => (
                                          <>

                                            <div class="">
                                              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">



                                                <tr>
                                                  <td class="border-t-0 px-11 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                                                    <span>{i + 1} .    </span> {questions.question.questions}
                                                  </td>
                                                  <td class="text-right border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">

                                                    <a target="_blank" href={ `/admin/question/show/${questions.question.id}` } class="pointer-events-auto ml-8 rounded-md bg-indigo-600 px-3 py-2 text-right font-semibold leading-5 text-white hover:bg-indigo-500">Show</a>
                                                  </td>

                                                </tr>


                                              </div>
                                            </div>
                                          </>
                                        ))}






                                      </tbody>

                                    </table>
                                  </div>
                                </ul>

                                {/* question */}


                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="mb-4">


                        </div>

                      </div>
                    </div>
            
              </>
            ))
            : ''}
          {/* < List selectedValues={selectedValues} /> */}




          {/* pulkit */}
        </div>



        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Important note
            </label>
            <p>{courseData ? courseData.CatData.important_note : ''}</p>
          </div>

        </div>

        <div className="mb-4 flex  gap-5">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Status
            </label>
            <p>{courseData ? courseData.CatData.status : ''}</p>
          </div>
        </div>
      </div>
      </div>
      <div className={` relative w-[30%]  !h-[660px] overflow-yy-scroll `}>
                    <div
                      className={`  ${isSticky ? "fixed top-[122px] right-7ff !h-fit rounded-md w-[22.6%] overflow-y-scroll " : "relative"
                        } w-full rounded-md bg-white py-20 px-9`}
                    >

                      <div className=" sticky  top-0 flex flex-col  gap-3 ">

                        <div className="mb-0 w-full ">
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-600"
                          >

                            Grade
                          </label>
                          <p>{courseData ? courseData.CatData.grade.name : ''}</p>
                        </div>
                        <div className="mb-0 w-full ">
                          <label
                            htmlFor="Subject"
                            className="block text-sm font-medium text-gray-600"
                          >
                            Subject
                          </label>
                          <p>{courseData ? courseData.CatData.subject.name : ''}</p>

                        </div>

                        <div className=" w-full ">
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-600"
                          >
                            Category
                          </label>
                          <div>
                            <p>{courseData ? courseData.chaptercategory.map((categoory) => (
                              <>
                                <p>{categoory.category.name}</p>
                              </>

                            )) : ''}</p>
                          </div>

                        </div>

                        <div className=" w-full ">
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-600"
                          >
                            Tag
                          </label>
                          <p>{courseData ? courseData.chaptertag.map((categoory) => (
                            <>
                              <p>{categoory.tag.name}</p>
                            </>

                          )) : ''}</p>
                        </div>

                        <div className="w-full">

                          <div className="mb-4 w-full px-3 md:w-1/2 lg:w-full">

                            <label className="mb-2  block text-sm font-bold" htmlFor="image">
                              Feature Image
                            </label>

                            {courseData?.CatData.image ?
                              <img height="200" width="200" src={`http://localhost:4001/${courseData.CatData.image}`} /> :
                              <img height="200" width="200" src="/course.jpg" />}



                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
      </div>
      <ToastContainer />
    </>
  );
}
