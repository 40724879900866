export default function List({ type , selectedValues }) {
    return (
      <ul className="divide-y divide-slate-100">
        <div class="block w-full overflow-x-auto">
                    <table class="items-center bg-transparent w-full border-collapse ">
                     
                      <tbody>
        {
            selectedValues?
            selectedValues.map((lecture) => (
            
              <div class="">
                <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
                
              
                  
                        <tr>
                          <td class="border-t-0 px-11 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                          { lecture.label }
                          </td>
                          <td class="text-right border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                          
                          <a target="_blank" href={ `/admin/${ type.toLowerCase() }/show/${lecture.value}` } class="pointer-events-auto ml-8 rounded-md bg-indigo-600 px-3 py-2 text-right font-semibold leading-5 text-white hover:bg-indigo-500">Show</a>
                          </td>
                         
                        </tr>
                     
                    
                </div>
              </div>
              
   

            ))
            :''
        }
        </tbody>
              
              </table>
            </div>
      </ul>
    )
  }

  