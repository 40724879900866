const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    routes: {}  
  };
const userRouteSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        add(state, action) {
            state.routes = action.payload;
        },
        remove(state, action) {
            return state.filter((item) => item.id !== action.payload);
        },
    },
});

export const { add, remove } = userRouteSlice.actions;
export default userRouteSlice.reducer;
