/* eslint-disable react-hooks/exhaustive-deps */
// DragDropModule.js
import React, { useState, useEffect } from "react";
import DraggableItem from "./DraggableItem";
import DropArea from "./DropArea";

const DragDropModule = ({ quedata, setSelectedQuestionss }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  useEffect(() => {
    setQuestions(quedata);
  }, [quedata]);

  const handleDragStart = (itemId) => {
    const selectedQuestion = questions.find(
      (question) => question.id === itemId
    );
    setSelectedQuestions((preData) => [...preData, selectedQuestion]);
    setSelectedQuestionss((preData) => [...preData, selectedQuestion]);
  };

  const handleRemove = (id) => {
    setSelectedQuestions((prevObjects) =>
      prevObjects.filter((obj) => obj.id !== id)
    );
    // const unselectedQuestions = !questions.filter((question) => selectedQuestions.find((selected) => selected.id === (question.id)));

    // console.log(unselectedQuestions,"khuy");

    // setQuestions(unselectedQuestions);
  };

  const handleDrop = (itemId) => {
    // const updatedSelectedQuestions = selectedQuestions.filter((question) => question.id === itemId);
    // setSelectedQuestions([...selectedQuestions, updatedSelectedQuestions]);
  };

  useEffect(() => {
    const unselectedQuestions = questions.filter(
      (question) =>
        !selectedQuestions.find((selected) => selected.id === question.id)
    );
    setQuestions(unselectedQuestions);
  }, [selectedQuestions]);

  useEffect(() => {
    setSelectedQuestionss(selectedQuestions);
  }, [selectedQuestions]);

  console.log(setSelectedQuestions, "setSelectedQuestionssetSelectedQuestions");

  return (
    <div className="relative">
      <div className=" flex h-full w-full items-start justify-between gap-3 p-10 ">
        <div className=" h-[490px] w-[50%] overflow-y-scroll rounded-xl border border-gray-700  p-3 ">
          <h2 className="text-center font-bold">Questions To Select </h2>
          {questions.map((question) => (
            <DraggableItem
              key={question.id}
              item={question}
              onDragStart={handleDragStart}
            />
          ))}
        </div>
        <div className="  h-[490px] w-[50%] overflow-y-scroll rounded-xl  border-2 border-dotted border-gray-700 ">
          <h2 className="py-3 text-center font-bold ">Selected Questions </h2>
          <DropArea
            handleDroppedRemove={handleRemove}
            selectedItems={selectedQuestions}
            onDrop={handleDrop}
          />
        </div>
      </div>
    </div>
  );
};

export default DragDropModule;
