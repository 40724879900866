/* eslint-disable no-unused-vars */
// VideoSelector.js

import React, { useState } from 'react';
import { FaFileVideo } from "react-icons/fa";
const VideoSelector = ({setVideoFiles}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewIndex, setPreviewIndex] = useState(null);
 
 //console.log(setVideoFiles,"selected ------------------------------------");

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles([...selectedFiles, ...files]);
        setVideoFiles(preData => [...preData, ...files] )
    };

    const openPreview = (index) => {
        setPreviewIndex(index);
        setShowPreview(true);
    };

    const closePreview = () => {
        setShowPreview(false);
        setPreviewIndex(null);
    };

    const removeFile = (index) => {
     console.log(index,'indexindex');
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        setVideoFiles(updatedFiles);
    };

    return (
        <div className=" w-full flex items-center justify-center py-5 rounded-xl  min-h-[200px] border-dotted border  border-gray-400   ">
            <div className=" flex items-center justify-center flex-col     ">

                <div className="  mb-3 flex  items-center justify-center  w-full  text-3xl  ">
                    <FaFileVideo />
                </div>
                <input
                    type="file"
                    accept="video/*"
                    multiple
                    onChange={handleFileChange}
                    className="hidden"
                    id="fileInput"
                />
                <label
                    htmlFor="fileInput"
                    className="px-3 py-2 cursor-pointer  border-2 border-blue-500 text-white rounded-md"
                >
                    Select Videos
                </label>

                {selectedFiles.length > 0 && (
                    <div className="mt-4 flex flex-wrap gap-4">
                        {selectedFiles.map((file, index) => (
                            <div key={index} className="relative">
                                <div
                                    className="z-50 absolute top-0 px-3 right-0 py-2 cursor-pointer"
                                    onClick={() => {removeFile(index)}}
                                >
                                    <span className="text-white  bg-red-500 p-1 rounded-full">&times;</span>
                                </div>
                                
                                <video
                                controls
                                alt={`Preview ${index}`}
                                src={URL.createObjectURL(selectedFiles[index])}
                                className="w-32 h-32 object-cover rounded-md"
                            />
                            </div>
                        ))}
                    </div>
                )}

                {showPreview && previewIndex !== null && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center">
                        <div className="relative">
                            <span
                                className="absolute top-0 right-0 p-2 cursor-pointer text-white"
                                onClick={closePreview}
                            >
                                &times;
                            </span>
                            <video
                                controls
                                src={URL.createObjectURL(selectedFiles[previewIndex])}
                                className="w-96 h-64 object-cover rounded-md"
                            />
                        </div>
                    </div>
                )}
            </div>
            
        </div>
    );
};

export default VideoSelector;
