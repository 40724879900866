const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isDark: false
  };
const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        dark(state, action) {
            state.isDark = action.payload;
        },
        remove(state, action) {
            return state.filter((item) => item.id !== action.payload);
        },
    },
});

export const { dark, remove } = themeSlice.actions;
export default themeSlice.reducer;
