import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  getTopicDataWithPagination,
  DeleteTopic,
  gradeGet,
  getSingleGradeAllSubjects,
} from "proxyUrl";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import LoaderComp from "components/common/LoaderComp";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../components/common/Pagination";
import Select from "react-select";
export default function Index() {
  const [submited, setSubmited] = useState(true);
  const [recordsPerPage] = useState(25);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // getting all data tags starts----------------------
  const [gradeData, setGradeData] = useState("");
  const [topicData, setTopicData] = useState("");
  const pagelength = totalPages ? totalPages : 0;
  const [subjectData, setSubjectData] = useState("");
  const [typedata, setTypedata] = useState(0);

  const [gradeData2, setGradeData2] = useState("");
  const [gradeSlug, setGradeSlug] = useState(null);

  const [err, setErr] = useState();

  const nPages = Math.ceil(pagelength / recordsPerPage);

  const handleGradeChange = async (e) => {
    // setSelectedGrade(e);
    setSelectedGrade(e);
    setSelectedSubject(null);
    setSelectedTopic(null);
    //  setSelectedSubTopic(null);
    console.log(e, "handle grade change");
    setSelectedGrade(e);
    console.log(gradeData2, "grade data raw dataaaa");
    console.log(
      gradeData2.find((element) => element.id === e.value).slug,
      "gradeData2[0].namegradeData2[0].name"
    );
    setGradeSlug(gradeData2.find((element) => element.id === e.value).slug);
  };
  const fetchTopics = async (currentPage) => {
    try {
      setLoading(true);
      await axios
        .post(getTopicDataWithPagination, {
          page: currentPage,
          //type: typedata,
          gradeId: selectedGrade ? selectedGrade.value : 0,
          subjectId: selectedSubject ? selectedSubject.value : 0,
        })
        .then((res) => {
          setTopics(res.data.data);
          setLoading(false);
          setTotalPages(res.data.totalCount);
          setSubmited(false);
        })
        .catch((err) => {
          setTopics([]);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  useEffect(() => {
    fetchTopics(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (id) => {
    await axios
      .delete(`${DeleteTopic}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Topic Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchTopics();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error("Topic Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
        setGradeData2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };

  const subjectDataFetch = async () => {
    try {
      const response = await axios.post(
        getSingleGradeAllSubjects,
        { gradeSlug: gradeSlug },
        { withCredentials: true }
      );
      const subjects = response.data.data.subjects;
      console.log(subjects, "single grade subjects");

      // Format subjects for rendering
      const formattedSubjects = subjects.map((subject) => ({
        value: subject.id,
        label: subject.name, // You can format it as needed
      }));

      setSubjectData(formattedSubjects);
    } catch (error) {
      console.error(error);
      setSubjectData([]);
    }
  };

  useEffect(() => {
    // Fetch subject data when selectedGrade changes
    if (selectedGrade) {
      subjectDataFetch(selectedGrade.value);
    }
  }, [selectedGrade]);
  useLayoutEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);
  useEffect(() => {
    //setTypedata(1);
    fetchTopics();
    setSelectedSubject(null);
    setCurrentPage(0);
  }, [selectedGrade]);
  useEffect(() => {
    // setTypedata(1);
    fetchTopics();
    //  setSelectedSubject(null);
    setCurrentPage(0);
  }, [selectedSubject]);
  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Topic List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/topic/add"}
            >
              {" "}
              Add Topic{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <LoaderComp submited={submited} />
          <div class="my-10 grid grid-cols-4 gap-4">
            <div className="mb-0 w-full ">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-600"
              >
                Grade
              </label>
              <Select
                defaultValue={selectedGrade}
                // onChange={setSelectedGrade}
                onChange={handleGradeChange}
                options={gradeData}
              />
              {err && err["grade"] ? (
                <>
                  {" "}
                  <p className=" pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    {err["grade"]}
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-0 w-full ">
              <label
                htmlFor="Subject"
                className="block text-sm font-medium text-gray-600"
              >
                Subject
              </label>
              <Select
                defaultValue={selectedSubject ? selectedSubject : null}
                onChange={setSelectedSubject}
                options={subjectData}
              />
              {err && err["subject"] ? (
                <>
                  {" "}
                  <p className=" pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    {err["subject"]}
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Id
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Name
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Grade
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Subject
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Status
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <> loading...</>
              ) : (
                <>
                  {topics[0] ? (
                    <>
                      {topics.map((topicValue, i) => (
                        <tr key={topicValue.id}>
                          <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                            {topicValue.id}
                          </td>
                          <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                            {topicValue.name}
                          </td>
                          <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                            {topicValue.grade?.name}
                          </td>
                          <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                            {topicValue.subject?.name}
                          </td>

                          <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                            <p className=" w-fit  rounded-full bg-blueSecondary py-1 px-4 text-sm text-white">
                              Active
                            </p>
                          </td>
                          <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                            <Link
                              to={`/admin/topic/edit/${topicValue.id}`}
                              className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                              // onClick={() => handleEdit(category.id)}
                            >
                              <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                            </Link>
                            <button
                              className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                              onClick={() => handleDelete(topicValue.id)}
                            >
                              <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        No Data Found
                      </td>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>

          {nPages != 1 ? (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            ""
          )}
        </div>
        <div className="mt-4 flex justify-between">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="focus:shadow-outline rounded bg-primaryBlue py-2 px-4 font-bold text-white hover:bg-blueSecondary focus:outline-none dark:bg-navy-900"
          >
            <MdOutlineDoubleArrow className=" rotate-180 text-3xl " />
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="focus:shadow-outline rounded bg-primaryBlue py-2 px-4 font-bold text-white hover:bg-blueSecondary focus:outline-none dark:bg-navy-900"
          >
            <MdOutlineDoubleArrow className="  text-3xl " />
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
