import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from "react";
import { FaEye, FaImage, FaRegCheckCircle, FaRegImage } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoaderComp from "components/common/LoaderComp";
import axios from "axios";
import {
    imageAdd,
    topicGet,
    getQuestionDataDirectly,
    gradeGet,
    subjectGet,
    getSingleGradeAllSubjects,
    allactiveQuestion,
    GetCategoryAndSubcategory,
    testAdd,
    QuestionsCreateByAi,
    chapterAllDataActive,
    chapterAllCategory,
    tagsAllData,
    questionFilter,
    getQuestionsByfilter,
    DescriptionwithAi,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./test.css";
import List from "../../../components/lecture/List";
import Select from "react-select";
import { ImCancelCircle } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import VideoDragDrop from "./DragComponents/VideoDragDrop";

import DragDropModule from "./DragComponents/DragDropModule";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdDownload, MdDownloading, MdOutlineCloudUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import AiTestComp from "./AiTestCompQuestions";
export default function TestAdd() {
    // model questions start
    const [open, setOpen] = useState(null);

    const handleClickOpen = (index) => {
        setOpen(index);
    };

    const handleClose = (index) => {
        setOpen(null);
    };
    // model question ends

    const logged_user_info = useSelector((state) => state.user.user);

    const Router = useNavigate();


    const [name, setName] = useState(null);
    const [slug, setSlug] = useState(null);
    const [description, setDescription] = useState(null);



    // Generate array of hours (0 to 23)
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minute = Array.from({ length: 60 }, (_, i) => i);
    const [selectedHour, setSelectedHour] = useState("");
    const [selectedMinute, setSelectedMinute] = useState("");

    const [isQuestionLoading, setIsQuestionsLoading] = useState(0);


    const [selected, setSelected] = useState([]);
    const [optionsCat, setoptionsCat] = useState([]);
    const [options, setoptions] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);

    const [checkselectederror, setcheckselectederror] = useState(null);
    const [checkselectederror2, setcheckselectederror2] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoRedUrl, setVideoRedUrl] = useState([]);
    //console.log(videoRedUrl,"videoRedUrlvideoRedUrlvideoRedUrl");
    const [selectedChapter, setSelectedChapter] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [CatSubData, setCatSubData] = useState([]);
    const [image, setImage] = useState("");
    const [imagedata, setImageData] = useState(null);
    //console.log(imagedata,"jhgfdghj");
    const [TagData, setTagData] = useState([]);
    const [sale_price, setSaleprice] = useState(null);
    const [checked, setChecked] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [questionsDefaultData, setQuestionsDefaultData] = useState([]);
    const [questionData, setQuestionData] = useState("");
    const [gradeData, setGradeData] = useState("");
    const [subjectData, setSubjectData] = useState("");
    const [topicData, setTopicData] = useState("");

    // this data is contains the id of the question after save in db so keep it safe
    const [questionsListToValidate, setQuestionsListToValidate] = useState([]);

    console.log(questionsListToValidate, "questionsListToValidate12345");
    const [selectedQuestionsCount, setSelectedQuestionsCount] = useState(0);

    const handleQuestionRemove = (index) => {
        const updatedQuestions = [...selectedQuestions];
        updatedQuestions.splice(index, 1);
        setSelectedQuestions(updatedQuestions);
    };

    const handleHourChange = (e) => {
        setSelectedHour(e.target.value);
    };
    const handleMinuteChange = (e) => {
        setSelectedMinute(e.target.value);
    };

    // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->
    const getQuesstionsDefalult = async () => {
        const dataQ = await axios
            .get(getQuestionDataDirectly)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log("Error In Getting Question Data", err);
                return [];
            });

        setQuestionsDefaultData(dataQ);
    };
    const [testImageFileName, setTestImageFileName] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const selectedImages = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files,
        };
        setTestImageFileName(file.name)
        setImage(selectedImages);
    };

    const handleSubmitImage = async (e) => {
        e.preventDefault();
        setSubmited(true);
        let formData = new FormData();
        formData.append("file", image.data[0]);

        axios
            .post(
                imageAdd,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                    withCredentials: false,
                }
            )
            .then((res) => {
                if (res.status === 201) {
                    toast.success("Image uploaded Successfully", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setImageData(res.data.path);
                    setErr(null);
                    setSubmited(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    toast.error("Please upload Image!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (err.response.status === 500) {
                    toast.error("Something Went Wrong!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setErr(null);
                    setSubmited(false);
                }
                setSubmited(false);
                return;
            });
    };
    useEffect(() => {
        getQuesstionsDefalult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->

    // const [image, setImage] = useState(null);
    const [err, setErr] = useState();
    const [WhatWillLearn, setWhatWillLearn] = useState(null);
    const [Requirements, setRequirements] = useState(null);

    const [status, setStatus] = useState("active");

    const [parentCategory, setParentCategory] = useState(null);
    const [submited, setSubmited] = useState(false);
    const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
    const [isQuwstionAddPopup, setIsQuwstionAddPopup] = useState(false);
    const [isQuwstionViewPopup, setIsQuwstionViewPopup] = useState(false);
    const [gradeData2, setGradeData2] = useState("");
    const [gradeSlug, setGradeSlug] = useState(null);

    const [images, setImages] = useState([]);

    const [isSticky, setIsSticky] = useState(false);

    const [videoFiles, setVideoFiles] = useState([]);
    const [videoUpdated, setVideoUpdate] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);

    const handleGradeChange = async (e) => {
        setSelectedSubject(null);
        setSelectedTopic(null);
        setSelectedGrade(e);
        setGradeSlug(gradeData2.find((element) => element.id === e.value).slug);
    };





    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            // Set isSticky based on scroll position
            if (offset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Attach the event listener on component mount
        window.addEventListener("scroll", handleScroll);

        // Detach the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const [chapterData, setChapterData] = useState(null);

    const gradeDataFetch = async () => {
        await axios
            .get(gradeGet)
            .then((res) => {
                setGradeData(res.data.formatteddata);
                setGradeData2(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                setGradeData(null);
            });
    };
    const topicDataFetch = async () => {
        await axios
            .post(
                topicGet,
                {
                    subjectId: selectedSubject.value ? selectedSubject.value : 0,
                    gradeId: selectedGrade.value ? selectedGrade.value : 0,
                },
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                setTopicData(res.data.formatteddata);
            })
            .catch((err) => {
                console.log(err);
                setTopicData(null);
            });
    };

    useEffect(() => {
        getdataFilter();
        topicDataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubject]);

    const subjectDataFetch = async () => {
        try {
            const response = await axios.post(
                getSingleGradeAllSubjects,
                { gradeSlug: gradeSlug },
                { withCredentials: true }
            );
            const subjects = response.data.data.subjects;

            // Format subjects for rendering
            const formattedSubjects = subjects.map((subject) => ({
                value: subject.id,
                label: subject.name, // You can format it as needed
            }));

            setSubjectData(formattedSubjects);
        } catch (error) {
            console.error(error);
            setSubjectData([]);
        }
    };
    // Ck Editor Data Starts-----------------------{
    const QuestionDataFromEditor = (e) => {
        setQuestionData(e);
    };
    // Ck Editor Data Ends-----------------------}

    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [isImageLoadingUploading, setIsImageLoadingUploading] = useState(false);

    useEffect(() => {
        setIsImageUploaded(false);
        setIsImageLoadingUploading(false);
    }, [image]);

    useEffect(() => {
        setSelectedQuestionsCount(selectedQuestions.length);
    }, [selectedQuestions]);

    ///////-----Submit Data Starts------//////////
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmited(true);

        const questionsIds = [];
        questionsListToValidate.map((data, index) => {
            const obg = {
                id: data.saved_id
            }
            questionsIds.push(obg);
        });

        await axios
            .post(
                testAdd,
                {
                    name,
                    slug,
                    description,
                    status,
                    timer: parseInt(selectedHour, 10) * 60 + parseInt(selectedMinute, 10),
                    selectedQuestions: questionsIds,
                    selectedTags,
                    selectedCategories,
                    selectedSubject,
                    selectedGrade,
                    videoRedUrl,
                    selectedTopic,
                    imagedata,
                    created_by: logged_user_info?.userInfo?.id || 1,
                },
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                toast.success("Test Created Successfully", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setErr(null);
                setSubmited(false);
                setTimeout(() => {
                    Router("/admin/test");
                }, 3000);
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    setSubmited(false);
                    setErr(err.response.data.errors);
                } else if (err.response.status === 500) {
                    toast.error("Something Went Wrong!", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setErr(null);
                    setSubmited(false);
                }
                setSubmited(false);
                return;
            });
    };

    const fetchCatSubData = async () => {
        axios
            .get(GetCategoryAndSubcategory)
            .then((res) => {
                setCatSubData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                setCatSubData([]);
            });
    };
    // CATEGORY OPTION .......... STARTING..........{
    const CategoryReData = [];
    if (CatSubData) {
        for (let i = 0; i < CatSubData.length; i++) {
            const id = CatSubData[i].id;
            const catName = CatSubData[i].name;
            const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
            const option = {
                value: id,
                label: catName,
                disabled: is_dissabled,
            };
            CategoryReData.push(option);
            const subCatData = CatSubData[i].subcategories;
            if (subCatData[0]) {
                for (let index = 0; index < subCatData.length; index++) {
                    const element = subCatData[index];
                    const subCatId = element.id;
                    const subCatName = element.name;
                    const is_dissabled = element.status === "inactive" ? true : false;
                    const option = {
                        value: subCatId,
                        label: subCatName,
                        disabled: is_dissabled,
                    };
                    CategoryReData.push(option);
                }
            }
        }
    }
    // CATEGORY OPTION .......... ENDING..........}

    // TAG OPTION .......... STARTING..........{
    const TagReData = [];
    if (TagData) {
        for (let i = 0; i < TagData.length; i++) {
            const id = TagData[i].id;
            const tagName = TagData[i].name;
            const is_dissabled = TagData[i].status === "inactive" ? true : false;
            const option = {
                value: id,
                label: tagName,
                disabled: is_dissabled,
            };
            TagReData.push(option);
        }
    }
    const getdataFilter = async () => {
        await axios
            .post(
                getQuestionsByfilter,
                {
                    gradeId: selectedGrade ? selectedGrade.value : 0,
                    subjectId: selectedSubject ? selectedSubject.value : 0,
                },
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                setQuestionsDefaultData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // const getQuestiondata = async () => {
    //     axios
    //         .post(
    //             questionDatalistfilter,
    //             {
    //                 selectedTags,
    //                 selectedCategories,
    //                 selectedSubject,
    //                 selectedGrade,
    //             },
    //             {
    //                 withCredentials: true,
    //             }
    //         )
    //         .then((res) => {
    //             console.log(res, "setoptions questions");
    //             setoptions(res.data.filterData);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setoptions([]);
    //         });
    // };

    const fetchTagData = async () => {
        axios
            .get(tagsAllData)
            .then((res) => {
                setTagData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                setTagData([]);
            });
    };
    useEffect(() => {
        fetchCatSubData();
        fetchTagData();
    }, []);

    useEffect(() => {
        if (selected.length > 0) {
            setSelectedQuestiondata(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (selectedChapter.length > 0) {
            setSelectedChapter(selectedChapter);
        }
    }, [selectedChapter]);
    useEffect(() => {
        gradeDataFetch();
        subjectDataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // Fetch subject data when selectedGrade changes
        if (selectedGrade) {
            subjectDataFetch(selectedGrade.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGrade]);

    const [questionFromApi, setQuestionFromApi] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);

    const [numOfQuestions, setNumOfQuestions] = useState(10);
    const [difficultyLevel, setDifficultyLevel] = useState('easy');

    const handleNumOfQuestionsChange = (event) => {
        setNumOfQuestions(event.target.value);
    };

    const handleDifficultyLevelChange = (event) => {
        setDifficultyLevel(event.target.value);
    };
    // field validation starts
    let isValid = true;
    let errors = {};
    const [isApproved, setIsApproved] = useState(false);

    const handleQuestionData = async () => {
        setSubmited(true);
        if (!selectedGrade) {
            isValid = false;
            errors.selectedGrade = "Grade is required.";
        }

        if (!selectedSubject) {
            isValid = false;
            errors.selectedSubject = "Subject is required.";
        }
        if (!selectedTopic) {
            isValid = false;
            errors.selectedTopic = "Topic is required.";
        }
        if (!selectedCategories[0]) {
            isValid = false;
            errors.selectedCategories = "Category is required.";
        }
        if (!selectedTags[0]) {
            isValid = false;
            errors.selectedTags = "Tag is required.";
        }

        setErr(errors);

        if (isValid) {
            setIsQuestionsLoading(1);
            setIsApproved(true);

            await axios.post(QuestionsCreateByAi, {
                "grade": selectedGrade.label,
                "subject": selectedSubject.label,
                "topic": selectedTopic.label,
                "categories": selectedCategories.map(option => option.label).reverse().join(', '),
                "tags": selectedTags.map(option => option.label).reverse().join(', '),
                "numOfQuestions": numOfQuestions,
                "difficultyLevel": difficultyLevel,
                "name": name,

            }).then((res) => {
                setSubmited(false);
                toast.success(" questions are generated by ai ", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setQuestionFromApi(res.data.data);
                // create empaty object for questions 
                res.data.data.map((_, index) => {
                    return setQuestionsListToValidate((oreQuestions) => [...oreQuestions, {
                        id: index,
                        saved_id: null,
                        image_url: null,
                        isApproved: false,
                        isSubmited: false,
                        isLoading: false,
                    }]);
                })
                setIsQuestionsLoading(2);
            }).catch((err) => {
                setIsQuestionsLoading(0);
                setSubmited(false);
                toast.error("Ai is Busy in anothe Importent work, baad me aana! ", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })



        } else {
            setIsApproved(false);
            setSubmited(false);
            toast.error("Please fill in all required fields correctly.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }



        // hare i am creating empty image fields

    }

    const [shouldConfirmUnload, setShouldConfirmUnload] = useState(true);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (shouldConfirmUnload) {
                const confirmationMessage = 'Are you sure you want to leave?';
                event.returnValue = confirmationMessage; // Show native dialog
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [shouldConfirmUnload]);

    useEffect(() => {
        if (!shouldConfirmUnload) {
            // Show SweetAlert2 dialog on page load for final confirmation
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to refresh the page?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, refresh it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                } else {
                    setShouldConfirmUnload(true); // Re-enable confirmation
                }
            });
        }
    }, [shouldConfirmUnload]);

    useEffect(() => {
        window.addEventListener('load', () => {
            if (!shouldConfirmUnload) {
                setShouldConfirmUnload(true);
            }
        });
    }, []);

    const [desSumited, setDesSubmited] = useState(false);

    const generateDesWithAi = async () => {
        setDesSubmited(true);
        setSubmited(true);
        await axios.post(DescriptionwithAi, {
            "grade": selectedGrade.label,
            "subject": selectedSubject.label,
            "topic": selectedTopic.label,
            "categories": selectedCategories.map(option => option.label).reverse().join(', '),
            "tags": selectedTags.map(option => option.label).reverse().join(', '),
            "name": name,
        }).then((res) => {
            setDesSubmited(false);
            setSubmited(false)
            toast.success(" Description Generated ", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setDescription(res.data.data);

        }).catch((err) => {
            setDesSubmited(false);
            setDesSubmited(false);
            toast.error("Ai is Busy in anothe Importent work, baad me aana! ", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }
    return (
        <>
            <div className="mx-auto flex w-full  py-6">
                <div className=" h-full w-full rounded-md bg-white dark:bg-navy-900  py-5  ">
                    <div className="category_form mx-auto  w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
                        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white/60">
                            Add Test With Ai
                        </h1>

                        <div className="mb-4 flex gap-5 ">
                            <div className={` relative w-full  `}>
                                <div
                                    className={` relative
                                        w-full `}
                                >
                                    <div className="  flex flex-col  gap-3 ">
                                        <div className=" flex  items-center justify-around gap-4 ">
                                            <div className="mb-0 w-full ">
                                                <label
                                                    htmlFor="category"
                                                    className="block text-sm font-medium text-gray-600"
                                                >
                                                    Grade
                                                </label>
                                                <Select
                                                    defaultValue={selectedGrade}
                                                    // onChange={setSelectedGrade}
                                                    onChange={handleGradeChange}
                                                    options={gradeData}
                                                />
                                                {err && err.selectedGrade ? (
                                                    <>
                                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                                            {err.selectedGrade}!
                                                        </p>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="mb-0 w-full ">
                                                <label
                                                    htmlFor="Subject"
                                                    className="block text-sm font-medium text-gray-600"
                                                >
                                                    Subject
                                                </label>
                                                <Select
                                                    defaultValue={selectedSubject}
                                                    onChange={setSelectedSubject}
                                                    options={subjectData}
                                                />
                                                {err && err.selectedSubject ? (
                                                    <>
                                                        {" "}
                                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                                            {" "}
                                                            {err.selectedSubject}!
                                                        </p>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="mb-0 w-full ">
                                                <label
                                                    htmlFor="Subject"
                                                    className="block text-sm font-medium text-gray-600"
                                                >
                                                    Topic
                                                </label>
                                                <Select
                                                    defaultValue={selectedTopic}
                                                    onChange={setSelectedTopic}
                                                    options={topicData}
                                                />
                                                {err && err.selectedTopic ? (
                                                    <>
                                                        {" "}
                                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                                            {" "}
                                                            {err.selectedTopic}!
                                                        </p>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className=" w-full ">
                                                <label
                                                    htmlFor="category"
                                                    className="block text-sm font-medium text-gray-600"
                                                >
                                                    Category
                                                </label>
                                                <MultiSelect
                                                    options={CategoryReData}
                                                    value={selectedCategories}
                                                    onChange={setSelectedCategories}
                                                    data={selectedCategories}
                                                    labelledBy="Select Category"
                                                    className=""
                                                />
                                                {err && err.selectedCategories ? (
                                                    <>
                                                        {" "}
                                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                                            {" "}
                                                            {err.selectedCategories}!
                                                        </p>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className=" w-full ">
                                                <label
                                                    htmlFor="category"
                                                    className="block text-sm font-medium text-gray-600"
                                                >
                                                    Tag
                                                </label>
                                                <MultiSelect
                                                    options={TagReData}
                                                    value={selectedTags}
                                                    onChange={setSelectedTags}
                                                    data={selectedTags}
                                                    labelledBy="Select Category"
                                                    className=" "
                                                />
                                                {err && err.selectedTags ? (
                                                    <>
                                                        {" "}
                                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                                            {" "}
                                                            {err.selectedTags}!
                                                        </p>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 flex gap-5 ">
                            <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                                    id="name"
                                    type="text"
                                    placeholder="Test Name"
                                    value={name}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        setName(newValue);

                                        // Automatically generate slug based on the name
                                        const newSlug = newValue
                                            .toLowerCase() // Convert to lowercase
                                            .replace(/\s+/g, "-") // Replace whitespace with hyphen
                                            .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                                        setSlug(newSlug);
                                    }}
                                />
                                {err && err.name ? (
                                    <>
                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                            {err.name}!
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                                    Slug
                                </label>
                                <input
                                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                                    id="slug"
                                    type="text"
                                    placeholder="test-slug"
                                    value={slug}
                                    onChange={(e) => {
                                        setSlug(e.target.value);
                                    }}
                                />
                                {err && err.slug ? (
                                    <>
                                        {" "}
                                        <p className=" pl-1 pt-1 text-sm text-red-500">
                                            {" "}
                                            {err.slug}!
                                        </p>{" "}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className=" flex items-center justify-between ">
                                <label
                                    className="mb-2 block text-sm font-bold "
                                    htmlFor="description"
                                >
                                    Description
                                </label>
                                <Button
                                    onClick={generateDesWithAi}
                                    variant="outlined"
                                    disabled={name && selectedGrade && selectedTags && selectedTopic && selectedSubject && !description ? false : true}
                                    className=' !text-[12px] flex items-center justify-center ' color="primary" >
                                    Generate Des with AI
                                </Button>
                            </div>
                            <div className=" relative w-full h-fit ">
                                <CkEditorComp
                                    predata={description ? description : ''}
                                    handleData={(e) => {
                                        setDescription(e);
                                    }}
                                />
                                {
                                    desSumited && (
                                        <div className=" w-full h-[70%] flex justify-center items-center gap-2 absolute top-10 left-0 bg-white/50 ">
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                        </div>
                                    )
                                }
                            </div>
                            {err && err.description ? (
                                <>
                                    <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                                        {" "}
                                        Description is Required!
                                    </p>{" "}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="w-full mb-4 flex justify-between">
                            <div className="w-1/2 mr-2">
                                <label htmlFor="num-of-questions" className="block mb-1">Number of Questions</label>
                                <select
                                    id="num-of-questions"
                                    className="block w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                                    value={numOfQuestions}
                                    onChange={handleNumOfQuestionsChange}
                                >
                                    {[...Array(15).keys()].map((num) => (
                                        <option key={num + 1} value={num + 1}>{num + 1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-1/2 ml-2">
                                <label htmlFor="difficulty-level" className="block mb-1">Difficulty Level</label>
                                <select
                                    id="difficulty-level"
                                    className="block w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                                    value={difficultyLevel}
                                    onChange={handleDifficultyLevelChange}
                                >
                                    <option value="easy">Easy</option>
                                    <option value="medium">Medium</option>
                                    <option value="hard">Hard</option>
                                </select>
                            </div>
                        </div>
                        <p className='mb-2 block text-sm font-bold  text-blueSecondary ' > Questions </p>

                        <div className=' flex flex-col border p-2 '>

                            {
                                isQuestionLoading === 1 ? (
                                    <>
                                        <div className="loader-4 flex w-full h-9 bg-primaryBlue items-center justify-center rounded gap-4 ">
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                            <div className="circle-5"></div>
                                        </div>
                                    </>
                                ) : isQuestionLoading === 0 ? (
                                    <>
                                        <Button disabled={name && selectedGrade && selectedTags && selectedTopic && selectedSubject && difficultyLevel ? false : true} onClick={handleQuestionData} variant="contained" color="primary" >
                                            Generate Questions With Ai
                                        </Button>
                                    </>
                                ) : isQuestionLoading === 2 && <Button onClick={handleQuestionData} disabled variant="contained" color="primary" >
                                    generated
                                </Button>
                            }
                            {
                                isQuestionLoading === 1 && (
                                    <>
                                        <div className="flex flex-col relative  mt-2 rounded  w-full animate-pulse duration-100 h-fit">

                                            <div className=" absolute top-0 left-0 w-full h-full  ">

                                            </div>

                                            {
                                                numOfQuestions && (
                                                    <>
                                                        {
                                                            Array(numOfQuestions).fill().map((_, index) => (
                                                                <div key={index} className="h-9 my-[2px] rounded w-full  bg-primaryBlue/20 dark:bg-black"></div>
                                                            ))
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                            {questionFromApi && questionFromApi.map((data, index) => (
                                <>
                                    <div className=" flex items-center my-1  justify-between ">

                                        <div className=" flex w-fit gap-3 items-center ">
                                            <p className=' flex items-center justify-center  rounded-md  text-gray-700  ' >  {index + 1}. </p>
                                            <p className=' w-full  overflow-hidden ' > {data.questionName}  </p>

                                        </div>
                                        <div className=" flex  gap-2">
                                            <Button disabled={questionsListToValidate[index].isApproved ? true : false} className=' !text-[12px] flex items-center justify-center ' variant="outlined" color="primary" >
                                                <FaRegCheckCircle className=' text-xl ' />
                                            </Button>
                                            <Button disabled={!questionsListToValidate[index].isApproved ? true : !questionsListToValidate[index].isSubmited ? false : true} className=' !text-[12px] flex items-center justify-center ' variant="outlined" color="primary" onClick={() => {
                                                handleClickOpen(index)
                                            }}>
                                                <MdOutlineCloudUpload className=' text-xl ' />
                                            </Button>
                                            <Button className=' !text-[12px] ' variant="outlined" color="primary" onClick={() => {
                                                handleClickOpen(index)
                                            }}>
                                                <FaEye className=' text-xl ' />
                                            </Button>
                                        </div>
                                    </div>
                                    <Dialog open={open === index} onClose={handleClose} maxWidth="md" fullWidth>
                                        <DialogContent>
                                            <AiTestComp questionsListToValidate={questionsListToValidate} setQuestionsListToValidate={setQuestionsListToValidate} grade={selectedGrade} subject={selectedSubject} topic={selectedTopic} category={selectedCategories} tag={selectedTags} key={index} index={index} data={data} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => {
                                                handleClose(index)
                                            }} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            ))}
                        </div>

                        <div className="  flex gap-3 w-full  items-center ">
                            <div className="mb-4  w-fit ">
                                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                                    Timer
                                </label>
                                <div className="mb-4 flex items-center py-2 rounded-sm border border-primaryBlue/20 justify-between bg-white dark:bg-navy-900 w-fit px-2  gap-5 ">
                                    <div className=" flex gap-2">
                                        <label htmlFor="hour"> Hour: </label>
                                        <select
                                            id="hour"
                                            value={selectedHour}
                                            onChange={handleHourChange}
                                            className=" !py-0 !px-0 cursor-pointer focus:outline-none !bg-transperent "
                                        >
                                            <option value="" >Select</option>
                                            {hours.map((hour) => (
                                                <option key={hour} value={hour}>
                                                    {hour}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex gap-2 ">
                                        <label htmlFor="hour"> Minute: </label>
                                        <select

                                            id="hour"
                                            value={selectedMinute}
                                            onChange={handleMinuteChange}
                                            className=" !py-0 !px-0  cursor-pointer focus:outline-none"
                                        >
                                            <option value="">Select</option>
                                            {minute.map((min) => (
                                                <option key={min} value={min}>
                                                    {min}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {err && err.timer ? (
                                    <>
                                        <p className=" mt-[-16px]  pl-1 pt-1 text-sm text-red-500">
                                            {" "}
                                            Time is Required!
                                        </p>{" "}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className=" px-2 relative py-[9px] w-full mt-[-2px]  bg-white border  border-primaryBlue/20 dark:bg-navy-900 rounded-sm ">

                                <div className=" absolute top-[-26px] text-primaryBlue dark:text-white  block text-sm font-bold  left-0 "> Image </div>
                                <div className="w-full  ">
                                    <form
                                        onSubmit={handleSubmitImage}
                                        enctype="multipart/form-data"
                                        className=" flex items-center gap-3    justify-between "
                                    >
                                        <label htmlFor="image"><FaRegImage className=" text-2xl " /></label>
                                        <p className="  text-primaryBlue dark:text-white w-full overflow-hidden " > {testImageFileName ? testImageFileName : ' No Image Selected '}</p>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="file"
                                            className="focus:shadow-outline w-full appearance-none hidden     leading-tight focus:outline-none"
                                            id="image"
                                            onChange={handleImageChange}
                                        />
                                        <Button
                                            variant="contained"
                                            className=" hover:border-transparent rounded border border-primaryBlue !py-0 px-2 text-sm font-semibold text-primaryBlue hover:bg-primaryBlue/70 hover:text-white"
                                            type="submit"
                                            disabled={testImageFileName && !imagedata ? false : true}
                                        >
                                            {imagedata ? 'uploaded' : 'upload'}

                                        </Button>
                                    </form>
                                </div>
                            </div>
                            {/* status start  */}
                            <div className="mb-4 flex w-full    gap-5">
                                <div className="mb-4 w-full   ">
                                    <label
                                        className="mb-2 block text-sm font-bold "
                                        htmlFor="status"
                                    >
                                        Status
                                    </label>

                                    <select
                                        className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                                        id="status"
                                        value={status}
                                        onChange={(e) => {
                                            setStatus(e.target.value);
                                        }}
                                    >
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end"

                        >

                            <Button onClick={handleSubmit} variant="contained" color="primary" >
                                Add Test
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <LoaderComp submited={submited} />

            <ToastContainer />
        </>
    );
}
