import { useState, useEffect,useRef, Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UploadImage } from "proxyUrl";

// import { Plugin } from '@ckeditor/ckeditor5-core';
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link', 'image',
];
// eslint-disable-next-line react-hooks/rules-of-hooks
// const quillRef = useRef();
// const handleCustomButton = () => {
//   // Your custom button logic here
//   const editor = quillRef.current.getEditor();
//   const cursorPosition = editor.getSelection().index || 0;
//   editor.insertText(cursorPosition, 'Custom Snippet', 'bold', true);
// };

// Custom plugin handling
const handleCustomPlugin = () => {
  // Your custom plugin logic here
};
// import PluginCkEditorMethtype from './Plogin.CKeditor.MethType';
export default function CkEditorComp({ handleData, predata, validation }) {

  const [ckData, setCkData] = useState(predata);

  const setDataFunction = (data) => {
    handleData(data);
    setCkData(data);
  }
  const showMyDialog = (e) => {
    window.open('/Default.aspx', 'MyWindow', 'width=800,height=700,scrollbars=no,scrolling=no,location=no,toolbar=no');
}
  useEffect(() => {
    // getAllCategorysData();
    setCkData(predata)
  }, [predata])
  return (
    <>
      <div className=" my-4 ">

        <Fragment>

          <CKEditor
            editor={ClassicEditor}
            config={{
              // plugins: [PluginCkEditorMethtype],
              toolbar: {
                shouldNotGroupWhenFull: true,
                items: [
                  'MethTypeButton',
                  // 'heading', '|',
                  // 'fontfamily', 'fontsize', '|',
                  // 'alignment', '|',
                  // 'fontColor', 'fontBackgroundColor', '|',
                  // 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                  // 'link', '|',
                  // 'outdent', 'indent', '|',
                  // 'bulletedList', 'numberedList', 'todoList', '|',
                  // 'code', 'codeBlock', '|',
                  // 'insertTable', '|',
                  // 'uploadImage', 'blockQuote', '|',
                  "heading",
                  "fontsize",
                  "alignment",
                  "fontColor",
                  "fontBackgroundColor",
                  "outdent",
                  "indent",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "imageUpload",
                  "mediaEmbed",
                  "insertTable",
                  "blockQuote",
                  "undo",
                  "redo",
                  "|",
                  "MathType",
                  "ChemType",
                  "newplugin"
                ],
              },
              ckfinder: {
                // The URL that the images are uploaded to.
                uploadUrl: `${UploadImage}`,

                // Enable the XMLHttpRequest.withCredentials property.
                withCredentials: true,
                // Headers sent along with the XMLHttpRequest to the upload server.
              },
            }}

            data={ckData}
            onReady={(editor) => {
              // Register your custom plugin here
              editor.ui.registry.addButton('customPlugin', {
                label: 'Custom Plugin',
                command: 'customPluginCommand',
                icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17h-2v-2h2zm0-4h-2V7h2zm0-6h-2V6h2z"/></svg>',
              });
              editor.commands.add('customPluginCommand', {
                exec: () => {
                  // Handle the custom plugin command here
                },
              });
              // You can store the "editor" and use when it is needed.
              
              //  console.log( 'Editor is ready to use!', editors );
            }}

            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ event, editor, data });
              setDataFunction(data)

            }}

          />
          
            {/* <button onClick={handleCustomButton}>Insert Custom Snippet</button> */}
 
          {
            validation ? (
              <>  <p className=" text-sm py-1 text-red-600 font-semibold">This Field is Required</p></>
            ) : <></>
          }

        </Fragment>
      </div>
    </>
  );
}
