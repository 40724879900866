import React from "react";
import { Link } from "react-router-dom";
const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  console.log(nPages, "nPagesnPagesnPagesnPages");
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const paginationno = [];
  console.log(pageNumbers, "pageNumberspageNumbers");
  //console.log(pageNumbers,'secondParpageNumberspageNumberstsecondPart')

  //   if(pageNumbers && pageNumbers.length > 8){

  // const midIndex = Math.floor(pageNumbers.length / 2);
  // console.log(midIndex,'midIndexmidIndex');
  // const firstPart = pageNumbers.slice(0, midIndex);
  // const secondPart = pageNumbers.slice(midIndex);
  // console.log(firstPart,'firstPartfirstPart')
  // console.log(secondPart,'secondPartsecondPart')

  //   }
  //   else{
  //    const  paginationno = pageNumbers;
  //   }
  // pageNumbers

  const goToNextPage = () => {
    if (currentPage !== nPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  console.log(currentPage, "current Page after next button");
  const goToPrevPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div
      key={currentPage}
      className="w-svw flex  items-center justify-center overflow-auto "
      aria-label="Page  navigation example "
    >
      <ul class="inline-flex w-full -space-x-px  py-10 text-sm ">
        <li>
          <Link
            to="#"
            class="mr-5 flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-3 leading-tight text-gray-500 ms-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={goToPrevPage}
          >
            Previous
          </Link>
        </li>
        {pageNumbers.map((pgNumber) => (
          <>
            {currentPage + 1 === pgNumber ? (
              <li>
                <Link
                  to="#"
                  aria-current="page"
                  class=" flex h-8 items-center justify-center rounded border border-gray-300  bg-primaryBlue px-3 leading-tight text-white hover:bg-green-400 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800  dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => setCurrentPage(pgNumber - 1)}
                >
                  {pgNumber}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to="#"
                  aria-current="page"
                  class="mx-1 flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => setCurrentPage(pgNumber - 1)}
                >
                  {pgNumber}
                </Link>
              </li>
            )}
          </>
        ))}
        <li>
          <Link
            to="#"
            className={`ml-5 flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            onClick={goToNextPage}
          >
            Next
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
