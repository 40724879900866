// DragDropModule.js
import React, { useState, useEffect } from 'react';
import DraggableItem from './DraggableItem';
import DropArea from './DropArea';

const DragDropModule = ({ quedata,setSelectedQuestionss}) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  useEffect(() => {
    setQuestions(quedata);
  }, [quedata]);


  const handleDragStart = (itemId) => {
    const selectedQuestion = questions.find((question) => question.id === itemId);
    setSelectedQuestions(preData =>   [...preData, selectedQuestion]  );
    setSelectedQuestionss(preData =>   [...preData, selectedQuestion]  );
  };

  const handleRemove = (id) =>{
    setSelectedQuestions(prevObjects => prevObjects.filter(obj => obj.id !== id));
    // const unselectedQuestions = !questions.filter((question) => selectedQuestions.find((selected) => selected.id === (question.id)));
    
    // console.log(unselectedQuestions,"khuy");
    
    // setQuestions(unselectedQuestions);
  }

  const handleDrop = (itemId) => {
    // const updatedSelectedQuestions = selectedQuestions.filter((question) => question.id === itemId);
    // setSelectedQuestions([...selectedQuestions, updatedSelectedQuestions]);
  };


  useEffect(() => {
    const unselectedQuestions = questions.filter((question) => !selectedQuestions.find((selected) => selected.id === question.id));
    setQuestions(unselectedQuestions);
  }, [selectedQuestions]);


  useEffect(() =>{
    setSelectedQuestionss( selectedQuestions );
  },[selectedQuestions]);


  return (
    <div>
      <div className=" w-full h-full flex p-10 gap-3 items-start justify-between ">
        <div className=" w-[50%] overflow-y-scroll border border-gray-700 p-3 rounded-xl  h-[490px] ">
          <h2 className='text-center font-bold'>Questions To Select </h2>
          {questions.map((question) => (
            <DraggableItem key={question.id} item={question} onDragStart={handleDragStart} />
          ))}
        </div>
        <div className="  border-dotted border-2 border-gray-700 rounded-xl  w-[50%] h-[490px] overflow-y-scroll ">
        <h2 className='text-center font-bold py-3 '>Selected Questions </h2>
          <DropArea handleDroppedRemove={handleRemove} selectedItems={selectedQuestions} onDrop={handleDrop} />
        </div>
      </div>
    </div>
  );
};

export default DragDropModule;
