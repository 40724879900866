/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { testAllData, testDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from '../../../components/common/Pagination';
import LoaderComp from "components/common/LoaderComp";
import { useSelector } from "react-redux";

export default function Index() {

  const userData = useSelector((state) => state.user.user);
  const [fromDate, setFromDate] = useState();
  const [testData, settestData] = useState(null);
  const [submited, setSubmited] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(25);
  const [totalcount, setTotalcount] = useState(0);

  // ---------------getting all data tags starts----------------------//
  const getAllTestsData = async () => {
    await axios.post(testAllData, {
      page: currentPage,
      user_id: userData.userInfo.id,
      date: fromDate ? fromDate : null,
    }).then(res => {
      settestData(res.data?.data);
      setTotalcount(res.data.count);
      setSubmited(false);
    }).catch(err => {
      settestData(null);
    })
  }
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = testData ? testData.slice(indexOfFirstRecord, indexOfLastRecord) : '';
  // const nPages = testData ? Math.ceil(testData?.length / recordsPerPage) : 0;

  const pagelength = totalcount ? totalcount : 0;
  const nPages = Math.ceil(pagelength / recordsPerPage);

  const handleDelete = async (id) => {
    await axios.delete(`${testDelete}/${id}`, {
      withCredentials: true
    }).then((res) => {
      toast.success("Test Deleted Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      getAllTestsData();
    }).catch((err) => {
      if (err.response.status === 400) {
        toast.success("Test  Not Found!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      if (err.response.status === 500) {
        toast.success("Internal Server Error!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    })
  }


  const getdataFilter = async () => {
    await axios
      .post(
        testAllData,
        {
          page: currentPage,
          type: 1,

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTotalcount(res.data.count);
        // setFilter(1);
        settestData(res.data.data);
        setSubmited(false);

      })
      .catch((err) => {
        console.log(err);
        settestData([]);
      });
  };
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllTestsData();
    // console.log(testData,'testDatatestData')
  }, []);

  useEffect(() => {
    getAllTestsData();
    // console.log(testData,'testDatatestData')
  }, [fromDate])
  useEffect(() => {
    setSubmited(true);
    // if (filter === 0) {
    getAllTestsData();
    // } else {
    //  getdataFilter();
    //  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      {/* <div><Link to='/admin/categories/add'>Add Category</Link></div>
    <div><Link to='/admin/categories/edit/7'>Edit Category</Link></div> */}
      <div className="mx-auto w-full rounded-lg overflow-hidden bg-white dark:bg-navy-800 max-w-screen-xl py-2 px-6">
        <div className=" mb-4 flex w-full  justify-between items-center pb-2 ">
          <div className=" flex items-center justify-start gap-3 ">
            <h1 className=" text-2xl font-bold dark:text-white  ">Test List</h1>
            <div className=" rounded-lg border-l-2 pl-2 border-primaryBlue ">

              <input id="fromdate" className=" focus:outline-none focus:border-none " onChange={(e) => {
                setFromDate(e.target.value)
              }} type="date" />
            </div>
          </div>
          <div className="">
            <Link className=" px-4 py-2 bg-white border-[3px] rounded-full font-bold hover:bg-blueSecondary hover:text-white border-primaryBlue/70" to={'/admin/test/add'}> Add Test </Link>
            <Link className=" px-4 py-2 bg-white border-[3px] rounded-full font-bold hover:bg-blueSecondary hover:text-white ml-4 border-primaryBlue/70" to={'/admin/test/addwithai'}>  Test With Ai </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  rounded-lg overflow-hidden dark:bg-navy-900  bg-white">
            <thead className=" rounded-lg overflow-hidden text-white">
              <tr>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Image
                </th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Name</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Grade</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Subject</th>

                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Status</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-center">Actions   </th>
              </tr>
            </thead>
            <tbody>
              <LoaderComp submited={submited} />
              {testData?.length === 0 ? <tr><td class="text-center" colspan="7"> No Data Found</td></tr> :

                testData ? (
                  <>
                    {testData.map((test) => (
                      <tr key={test.id}>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">

                          {test.image ?
                            <img height="50" width="50" src={`${test.image}`} /> :
                            <img height="50" width="50" src="/course.jpg" />}

                        </td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{test.name} </td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{test?.grade?.name}</td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{test?.subject?.name}</td>



                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">
                          {test.status === 'active' ?
                            <p className=" text-sm py-1  bg-blueSecondary w-fit px-4 text-white rounded-full" >{test.status}</p>
                            :
                            <p className=" text-sm  bg-red-700 py-1  w-fit px-4 text-white rounded-full" >{test.status}</p>
                          }

                        </td>

                        <td className="border  dark:text-white  flex  gap-3 justify-center items-center  dark:border-navy-800   px-4 text-center">

                          <Link to={`/admin/test/edit/${test.id}`}
                            className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                          // onClick={() => handleEdit(category.id)}
                          >
                            <RiEditCircleFill className=" text-3xl hover:text-primaryBlue/80 dark:text-white text-primaryBlue" />
                          </Link>



                          <button
                            className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                            onClick={() => handleDelete(test.id)}
                          >
                            <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : <>
                  <td colSpan="4" className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>

                </>
              }

            </tbody>
          </table>
          {
            nPages !== 1 ?
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              :
              ''

          }


          {/* pagination */}
        </div>

      </div>
      <ToastContainer />
    </>
  );
}
