import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DragDropModule from "./DragComponents/DragDropModule";

import {
  videoUpload,
  contentUpdate,
  chapterAllDataActive,
  imageAdd,
  chapterAllCategory,
  tagsAllData,
  contentDataById,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./content.css";
import List from "../../../components/lecture/List";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import VideoDragDrop from "./DragComponents/VideoDragDrop";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";
const animatedComponents = makeAnimated();
export default function ContentAdd() {
  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedcat, setSelectedcat] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [checkselectederror, setcheckselectederror] = useState(null);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imagedata, setImageData] = useState(null);
  const [slug, setSlug] = useState(null);
  const [catData, setCatData] = useState(null);
  const [catDataFiroApi, setCatDataFromApi] = useState(null);
  const [videoFiles, setVideoFiles] = useState([]);
  const [videoUpdated, setVideoUpdate] = useState(false);
  const [videoRedUrl, setVideoRedUrl] = useState([]);
  const [videoFile, setVideoFile] = useState(null);

  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [questionData, setQuestionData] = useState("");

  const [status, setStatus] = useState("active");

  //console.log(categoryType,98);
  const [submited, setSubmited] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  const params = useParams();
  const { id } = params;
  const handleVideoFileChange = async (e) => {
    e.preventDefault();
    setVideoLoading(true);
    const chosenFiles = Array.prototype.slice.call(videoFiles);

    chosenFiles.map((data) => {
      if (!data) {
        alert("Please select a video file to upload.");
        return;
      }
      const chunkSize = 5 * 1024 * 1024;
      const totalChunks = Math.ceil(data.size / chunkSize);
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;
      const uploadNextChunk = async () => {
        if (chunkNumber < totalChunks) {
          const chunk = data.slice(start, end);
          console.log(chunk, "chunkchunk");
          const formData = new FormData();
          formData.append("Chunk", chunk);
          formData.append("ChunkNumber", chunkNumber);
          formData.append("TotalChunks", totalChunks);
          formData.append("FileName", data.name);
          formData.append("FileSize", data.size);
          // formData.append("File Size:", videoBuffer.length)

          try {
            const response = await axios
              .post(videoUpload, formData, {
                withCredentials: true,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.status === 201) {
                  setVideoRedUrl((prevData) => [...prevData, res.data.url]);
                  setVideoLoading(false);
                  setVideoUpdate(true);
                  toast.success("Video uploaded Successfully", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  console.log(res, "resresres");
                  const temp = `Chunk ${
                    chunkNumber + 1
                  }/${totalChunks} uploaded successfully`;
                }

                chunkNumber++;
                start = end;
                end = start + chunkSize;
                uploadNextChunk();
              })
              .catch((err) => {
                console.log(err);
                setVideoLoading(false);
              });
          } catch (error) {
            setVideoLoading(false);
            console.error("Error uploading chunk:", error);
            toast.error("Error During the uploading the video!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          setVideoFile(null);
          setVideoLoading(false);
        }
      };

      uploadNextChunk();
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [chapterData, setChapterData] = useState(null);

  // Ck Editor Data Starts-----------------------{
  const QuestionDataFromEditor = (e) => {
    setQuestionData(e);
  };
  // Ck Editor Data Ends-----------------------}

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        contentUpdate,
        {
          id,
          name,
          description,
          status,
          videoRedUrl,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("content Updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() => {
          Router("/admin/content");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });

    //console.log(selectedValues, 'selectedValues');
  };

  const getCourseDataId = async () => {
    await axios
      .get(`${contentDataById}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res.data,'casasasaatDatacatData')
        setCatDataFromApi(res.data);
        setName(res.data.CatData.name);
        setSlug(res.data.CatData.slug);
        setDescription(res.data.CatData.description);

        setCatData(res.data);
        //console.log(catData,'catDatacatData')
      })
      .catch((err) => {
        setCatData(null);
      });
  };

  useEffect(() => {
    getCourseDataId();
  }, [id]);

  useEffect(() => {
    if (selected.length > 0) {
      // console.log(selected, 'selectedselected');
      setSelectedQuestiondata(selected);
    }
  }, [selected]);

  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
        <div className=" h-full w-[100%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Update Content
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Content Name"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                Video
              </label>

              <VideoDragDrop setVideoFiles={setVideoFiles} />
              {videoFiles[0] ? (
                <>
                  {videoLoading ? (
                    <>
                      <div className="mt-3 text-end ">
                        <button
                          disabled
                          className="rounded-md bg-blue-200 py-2 px-4 text-white hover:bg-blue-300 focus:outline-none"
                        >
                          Uploading....
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {videoUpdated ? (
                        <>
                          <div className="mt-3 text-end ">
                            <button className="rounded-md bg-green-500 py-2 px-4 text-white hover:bg-green-600 focus:outline-none">
                              Uploaded
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-3 text-end ">
                            <button
                              onClick={handleVideoFileChange}
                              className="rounded-md bg-blue-500 py-2 px-4 text-white hover:bg-blue-600 focus:outline-none"
                            >
                              Upload Videos
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
                predata={description ? description : ""}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label
                  className="mb-2 block text-sm font-bold "
                  htmlFor="status"
                >
                  Status
                </label>

                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            {/* Additional fields can be added similarly */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}
              >
                Update Content
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
