// DraggableItem.js
import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableItem = ({ item, onDragStart }) => {
  const [, drag] = useDrag({
    type: 'ITEM',
    item: () =>{
        onDragStart(item.id)
        return {
            id:item.id
        }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });

  // trim string start 
  const trimString = (str) => {
    if (str.length > 180) {
      return str.slice(0, 180) + '...';
    } else {
      return str;
    }
  }
  

  return (
    <div className='  rounded-sm  ' ref={drag} style={{ cursor: 'move', border: '1px solid #ccc', padding: '8px', marginBottom: '8px' }}>
                        {item.id}.  {trimString(item.text)}
    </div>
  );
};

export default DraggableItem;
