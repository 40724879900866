import React, { useState, useEffect, useLayoutEffect } from "react";
import CkEditorComp from "components/common/CkEditorComp";
import { useParams } from "react-router-dom";
import "./question.css";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  GetCategoryAndSubcategory,
  GetPerentCat,
  QuestionUpdateApi,
  tagsAllData,
  gradeGet,
  topicGet,
  subjectGet,
  courseAllData,
  questionDataById,
} from "proxyUrl";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "components/common/LoaderComp";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
export default function QuestionEdit() {
  const Navigate = useNavigate();


  // Get ID from URL
  const params = useParams();
  const { id } = params;
  // console.log(id, 987654567);
  const [catDataFiroApi, setCatDataFromApi] = useState(null);
  const [catData, setCatData] = useState(null);
  const [description, setDescription] = useState(null);

  const [CourseDataFropApi, setCourseDataFropApi] = useState(null);
  const [SelectedCourse, setSelectedCourse] = useState([]);
  const courseDataFetch = async () => {
    await axios
      .get(courseAllData)
      .then((res) => {
        setCourseDataFropApi(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setCourseDataFropApi(null);
      });
  };
  useLayoutEffect(() => {
    courseDataFetch();
  }, []);

  const getQuestionDataId = async () => {
    await axios
      .get(`${questionDataById}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res.data, "casasasaatDatacatData");
        setCatDataFromApi(res.data);
        setCatData(res.data);
        const newdata = [];
        res.data.QueData?.option.map((data) => {
          // console.log(data,'QueoptionQueoption111111111111');
          var co_data = {
            name: data.name,
            question_id: data.question_id,
            image: data.image,
            is_correct: data.is_correct,
            description: data.description,
          };

          newdata.push(co_data);
        });
        setOptions(newdata);
        //console.log(catData,'catDatacatData')
      })
      .catch((err) => {
        setCatData(null);
      });
  };
  useEffect(() => {
    if (catDataFiroApi) {
      setName(catDataFiroApi.QueData.questions);
      setDescription(catDataFiroApi.QueData.description);
      setSlug(catDataFiroApi.QueData.slug);
      setStatus(catDataFiroApi.QueData.status);

      setQuestionData(catDataFiroApi.QueData?.content);
      setQuestionAnswer(catDataFiroApi.QueData?.answer?.answer);

      // tag
      var arrayTag = new Array();
      catDataFiroApi.QueData?.tagdata?.forEach(function (element, i) {
        //console.log(element.tag,'elementelement');
        if (element.tag?.name != null) {
          var co_data1 = {
            label: element.tag?.name,
            value: element.tag?.id.toString(),
          };
          arrayTag.push(co_data1);
        }
      });
      setSelectedTags(arrayTag);

      // category

      var arrayCat = new Array();
      catDataFiroApi.QueData?.categorydata?.forEach(function (element, i) {
        //console.log(element.tag,'elementelement');
        if (element.category?.name != null) {
          var co_data1 = {
            label: element.category?.name,
            value: element.category?.id.toString(),
          };
          arrayCat.push(co_data1);
        }
      });
      setSelectedCategories(arrayCat);

      // console.log(catDataFiroApi.QueData?.question_grades,'pooooooooooooooooooooo');
      setSelectedSubject({
        label: catDataFiroApi.QueData?.subject?.subject?.name,
        value: catDataFiroApi.QueData?.subject?.subject?.id,
      });

      setSelectedGrade({
        label: catDataFiroApi.QueData?.question_grades?.grade?.name,
        value: catDataFiroApi.QueData?.question_grades?.grade?.id,
      });
      setSelectedTopic({
        label: catDataFiroApi.QueData?.topic?.name,
        value: catDataFiroApi.QueData?.topic?.id,
      });
    }
  }, [catDataFiroApi]);
  useEffect(() => {
    getQuestionDataId();
  }, [id]);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [Content, setContent] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [questionData, setQuestionData] = useState("");
  const [questionAnswer, setQuestionAnswer] = useState("");

  const [CatSubData, setCatSubData] = useState([]);

  const [options, setOptions] = useState([
    { name: "", question_id: "", image: "", is_correct: "", description: "" },
  ]);
  const handleInputChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  // Function to remove a set of input fields
  const removeInputFields = (index) => {
    const updatedFields = [...options];
    updatedFields.splice(index, 1);
    setOptions(updatedFields);
  };

  // option data start

  const [status, setStatus] = useState("active");
  const [type, setType] = useState("single");
  const [categoryType, setCategoryType] = useState("0");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [PerentCatDataFromApi, setPerentCatDataFromApi] = useState(null);

  const getAllPerentCatData = async () => {
    await axios
      .get(GetPerentCat)
      .then((res) => {
        setPerentCatDataFromApi(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setPerentCatDataFromApi(null);
      });
  };

  useEffect(() => {
    getAllPerentCatData();
  }, []);

  ///////getting all grades and topics data start ------{
  const [gradeData, setGradeData] = useState("");
  const [topicData, setTopicData] = useState("");
  const [subTopicData, setSubTopicData] = useState("");
  const [subjectData, setSubjectData] = useState("");

  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);

  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubjectData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setSubjectData(null);
      });
  };
  const topicDataFetch = async () => {
    await axios
      .post(
        topicGet,
        {
          subjectId: selectedSubject ? selectedSubject?.value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };

  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);

  useEffect(() => {
    topicDataFetch();
  }, [selectedSubject]);

  ///////getting all grades and topics data ends ------}

  ///////-----Submit Question Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        QuestionUpdateApi,
        {
          id: id,
          questions: name,
          slug: slug,
          status: status,
          type: type,
          content: questionData,
          options: options,
          categories: selectedCategories,
          tags: selectedTags,
          questionanswer: questionAnswer,
          grade: selectedGrade,
          subject: selectedSubject,
          topic: selectedTopic,
          subtopic: selectedSubTopic,
          // answer : answer,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Question Updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          Navigate("/admin/questions");
        }, 3000);
        setErr(null);
        setSubmited(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };

  // Ck Editor Data Starts-----------------------{
  const QuestionDataFromEditor = (e) => {
    setQuestionData(e);
  };
  // Ck Editor Data Ends-----------------------}

  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };

  const [TagData, setTagData] = useState([]);
  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);

  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }
  // TAG OPTION .......... ENDING..........}
  console.log(selectedTopic, "optionsoptionsoptions");
  const renderInputFields = () => {
    return options.map((option, index) => (
      <div className={`correct_${option.is_correct}`} key={index}>
        <div className=" border border-blueSecondary px-7  py-4 ">
          <div className="">
            {" "}
            <p className=" pb-1 font-bold text-primaryBlue">
              Option {index + 1}
            </p>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div className="  w-full  ">
              <input
                className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                id="name"
                type="text"
                placeholder="Option Name"
                value={option.name || ""}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              />
              {err && err[`options[${index}].name`] ? (
                <>
                  <p className=" pl-1 pt-1 text-sm text-red-500">
                    {err[`options[${index}].name`]}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="  flex ">
            <div className="w-[50%]">
              <div className=" w-full  md:w-1/2 lg:w-full">
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="is_correct"
                  value={option.is_correct || ""}
                  onChange={(e) =>
                    handleInputChange(index, "is_correct", e.target.value)
                  }
                >
                  <option value="">Is Correct..</option>
                  <option value="1">True</option>
                  <option value="">False</option>
                </select>
              </div>
            </div>
            <div className="w-[30%]">
              <input
                className="focus:shadow-outline w-full  appearance-none rounded border bg-white  !py-[10px] leading-tight  focus:outline-none"
                id="name"
                type="file"
                onChange={(e) =>
                  handleInputChange(index, "image", e.target.files[0])
                }
              />
              <div></div>
            </div>
            <div className="w-[20%]">
              <img className="h-10 w-10 rounded" src={option.image || ""} />
            </div>
          </div>
          <div className="mb-4">
            <textarea
              className="focus:shadow-outline h-12 w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="description"
              placeholder="Option Description"
              rows="3"
              value={option.description || ""}
              onChange={(e) =>
                handleInputChange(index, "description", e.target.value)
              }
            ></textarea>
            {err && err[`options[${index}].name`] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err[`options[${index}].description`]}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          {index === 0 ? (
            <></>
          ) : (
            <>
              <div className=" flex w-full items-center justify-end">
                <button
                  className=" rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500 "
                  type="button"
                  onClick={() => removeInputFields(index)}
                >
                  Remove Set
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <form>
            <div className=" category_form "></div>
            <div className="">
              {" "}
              <p className=" text-2xl font-bold text-blueSecondary">
                {" "}
                Edit Question
              </p>
            </div>
            <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
              <div className="mb-4 flex gap-5 ">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="name"
                  >
                    Question Name
                  </label>
                  <input
                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                    id="name"
                    type="text"
                    placeholder="Question Name"
                    value={name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setName(newValue);

                      // Automatically generate slug based on the name
                      const newSlug = newValue
                        .toLowerCase() // Convert to lowercase
                        .replace(/\s+/g, "-") // Replace whitespace with hyphen
                        .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                      setSlug(newSlug);
                    }}
                  />
                  {err && err.questions ? (
                    <>
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {err.questions}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="slug"
                  >
                    Slug
                  </label>
                  <input
                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                    id="slug"
                    type="text"
                    placeholder="question-slug"
                    value={slug}
                    onChange={(e) => {
                      setSlug(e.target.value);
                    }}
                  />
                  {err && err.slug ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err.slug}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="mb-4 flex  gap-5">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="status"
                  >
                    Type
                  </label>
                  <select
                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                    id="status"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="single">Single</option>
                    <option value="multiple">Multiple</option>
                  </select>
                </div>
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="status"
                  >
                    Status
                  </label>
                  <select
                    className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                    id="status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <p className=" mb-[-9px] text-sm font-bold text-primaryBlue ">
                  {" "}
                  Content{" "}
                </p>
                <CkEditorComp
                  handleData={(e) => {
                    setQuestionData(e);
                  }}
                  predata={questionData ? questionData : ""}
                />
                {err && err.content ? (
                  <>
                    <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      content is Required!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Add your submit button here */}
          </form>
          <form>
            <div className="mb-4">
              <p className=" mb-[-9px] text-sm font-bold text-primaryBlue ">
                {" "}
                Answer{" "}
              </p>
              <CkEditorComp
                handleData={(e) => {
                  setQuestionAnswer(e);
                }}
                predata={questionAnswer ? questionAnswer : ""}
              />
              {err && err.questionanswer ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    {err.questionanswer}
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
              {renderInputFields()}

              <div
                className=" mt-3 w-fit rounded-md bg-primaryBlue  px-5 py-2 text-white "
                onClick={() =>
                  setOptions([
                    ...options,
                    {
                      name: "",
                      question_id: "",
                      image: "",
                      is_correct: "",
                      description: "",
                    },
                  ])
                }
              >
                Add Set
              </div>
              <LoaderComp submited={submited} />
            </div>

            {/* Add your submit button here */}
          </form>
        </div>

        {/* /// sidebar */}
        <div className={` relative !h-[550px]  w-[30%]  `}>
          <div
            className={`  ${
              isSticky ? "  " : "relative"
            } w-full rounded-md bg-white py-20 px-9`}
          >
            <div className="absolute top-10 right-10 flex justify-end ">
              <button
                onClick={handleSubmit}
                className="focus:shadow-outline button_primary rounded  py-2 px-4 font-bold text-primaryBlue  focus:outline-none"
                type="submit"
                // onClick={handleSubmit}
              >
                Update Question
              </button>
            </div>
            <div className=" sticky  top-0 flex flex-col  gap-3 ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Grade
                </label>
                {selectedGrade ? (
                  <>
                    <Select
                      defaultValue={selectedGrade}
                      onChange={setSelectedGrade}
                      options={gradeData}
                    />
                  </>
                ) : (
                  <></>
                )}

                {err && err["grade"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["grade"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Subject
                </label>
                {selectedSubject ? (
                  <Select
                    defaultValue={selectedSubject ? selectedSubject : ""}
                    onChange={setSelectedSubject}
                    options={subjectData}
                  />
                ) : (
                  ""
                )}

                {err && err["subject"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["subject"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Topic
                </label>
                {selectedTopic ? (
                  <Select
                    defaultValue={selectedTopic}
                    onChange={setSelectedTopic}
                    options={topicData ? topicData : []}
                  />
                ) : (
                  "-"
                )}

                {err && err["topic"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["topic"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Category
                </label>
                <MultiSelect
                  options={CategoryReData}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  data={selectedCategories}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err["categories"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["categories"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Tag
                </label>

                <MultiSelect
                  options={TagReData}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err["tags"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["tags"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="course"
                  className="block text-sm font-medium text-gray-600"
                >
                  Course
                </label>
                <MultiSelect
                  options={CourseDataFropApi ? CourseDataFropApi : []}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
