// DropArea.js
import React from 'react';
import { useDrop } from 'react-dnd';
import { MdOutlineRemoveDone } from "react-icons/md";
const DropArea = ({ selectedItems, onDrop, handleDroppedRemove }) => {
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => onDrop(item.id),
  });

  const HandleRemove = (id) => {
    handleDroppedRemove(id);
  };
  const trimString = (str) => {
    if (str.length > 180) {
      return str.slice(0, 180) + '...';
    } else {
      return str;
    }
  }
  return (
    <div ref={drop} className=' rounded-xl p-3 h-[490px]' >
      {
        selectedItems[0] ? (
          <>
            {
              selectedItems.map((item) => (
                <>
                  <div className=" relative border-2 bg- rounded-sm bg-primaryBlue/40  border-gray-200 mb-2 px-2 py-2 ">
                    {item.id}. {trimString(item.text)}
                    <button onClick={() => HandleRemove(item.id)} type="button" className=" absolute bottom-0 right-0 w-8 h-8 rounded-lg flex items-center justify-center text-red-600 hover:bg-red-300 hover:text-white        bg-gray-200 " >
                      <MdOutlineRemoveDone />
                    </button>

                  </div>

                </>
              ))
            }
          </>
        ) : <>
          <div className=" flex justify-center items-center  w-full h-full text-4xl font-bold text-gray-300 "><p> Drag And Drop Quiestions Hare</p> </div>
        </>
      }
    </div>
  );
};

export default DropArea;
