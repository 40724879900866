import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {userAuthentication } from "proxyUrl";
import axios from "axios";
const ProtectedRoute = (props) => {

 

  const navigate = useNavigate();
  const { Component } = props;

// AUTH FUNCTION START...........{
  const authFunction = async () =>{
  await  axios.get(userAuthentication,
    {
      withCredentials: true
    }).then((res) => {
      if (res.status === 201) {
        navigate("/auth/sign-in", { replace: true });
        return;
      } else if (res.status === 200) {
        if (window.location.pathname === "/auth/sign-in" || window.location.pathname === "/" ) {
          navigate("/admin/dashboard", { replace: true });
        }
      }
    });
  };
// AUTH FUNCTION ENDS...........}

  useEffect(() => {
    authFunction();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

return (
  <>
    <Component />
  </>
);
  
};

export default ProtectedRoute;
