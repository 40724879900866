import React, { useState, useEffect } from "react";
import axios from "axios";
import CkEditorComp from "components/common/CkEditorComp";

import {
  AddTopic,
  gradeGet,
  getSingleTopicById,
  subjectGet,
  updateTopic,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Category/category.css";
import LoaderComp from "components/common/LoaderComp";
export default function TopicEdit() {
  const params = useParams();
  const { id } = params;
  const [topicDataFiroApi, setTopicDataFromApi] = useState(null);

  const getAllTagsData = async (id) => {
    await axios
      .get(`${getSingleTopicById}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTopicDataFromApi(res.data);
      })
      .catch((err) => {
        setTopicDataFromApi(null);
      });
  };

  useEffect(() => {
    getAllTagsData(id);
  }, [id]);

  console.log(id, 87654);
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [err, setErr] = useState();

  const [submited, setSubmited] = useState(false);
  const [description, setDescription] = useState(null);
  const [optionstopic, setOptionstopic] = useState([]);
  const [deletedtopic, setDeletedtopic] = useState([]);

  const [gradeId, setGradeId] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [hasEffectRun, setHasEffectRun] = useState(true);

  const fetchAllGeade = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGrade(res.data.data);
      })
      .catch((err) => {
        setGrade([]);
      });
  };
  const fetchAllSubject = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubject(res.data.data);
      })
      .catch((err) => {
        setSubject([]);
      });
  };
  useEffect(() => {
    fetchAllGeade();
    fetchAllSubject();
  }, []);

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .put(
        updateTopic,
        {
          id: id,
          name: name,
          slug: slug,
          subject_id: subjectId,
          grade_id: gradeId,
          subtopic: optionstopic,
          deletedtopic: deletedtopic,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Topic Updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };

  useEffect(() => {
    if (topicDataFiroApi) {
      // console.log(topicDataFiroApi,765567);
      setName(topicDataFiroApi.name);
      setSlug(topicDataFiroApi.slug);
      setSubjectId(topicDataFiroApi.subject_id);
      setGradeId(topicDataFiroApi.grade_id);
      setDescription(topicDataFiroApi.description);

      topicDataFiroApi.subtopic
        ? topicDataFiroApi.subtopic.forEach(function (data, index) {
            const fulltopic = [];
            if (data.is_deleted === "0") {
              const data3 = {
                id: data.id,
                title: data.sub_topic_name,
                description: data.description,
                is_deleted: data.is_deleted,
              };
              console.log(data3, "data3data3");
              setOptionstopic((prevItems) => [...prevItems, data3]);
            }
          })
        : setOptionstopic([]);
      // console.log(newOptions,'newOptionsnewOptions');
    }
    //  setHasEffectRun(false);
  }, [topicDataFiroApi]);
  const handleInputChange = (index, field, value) => {
    // console.log(index, field, value,'valuevaluevaluevalue');

    const newOptions = [...optionstopic];
    newOptions[index][field] = value;
    // console.log(newOptions,'newOptionsnewOptionsnewOptionss');
    setOptionstopic(newOptions);
  };
  const removeInputFields = (index) => {
    const updatedFields = [...optionstopic];
    setDeletedtopic((prevItems) => [...prevItems, optionstopic[index]?.id]);
    updatedFields.splice(index, 1);
    setOptionstopic(updatedFields);
  };
  console.log(deletedtopic, "deletedtopic");
  const renderInputFields = () => {
    return optionstopic.map((option, index) => (
      <div key={index}>
        <div className=" border border-blueSecondary px-7  py-4 ">
          <div className="">
            <p className=" pb-1 font-bold text-primaryBlue">
              Sub Topic {index + 1}
            </p>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Title
                </label>

                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Sub Topic Name"
                  value={optionstopic[index]?.title}
                  onChange={(e) =>
                    handleInputChange(index, "title", e.target.value)
                  }
                />
                {err && err["title"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["title"]}{" "}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Description
                </label>

                <CkEditorComp
                  predata={optionstopic[index]?.description}
                  handleData={(e) => {
                    handleInputChange(index, "description", e);
                  }}
                />
                {err && err["description"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["description"]}{" "}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {index === 0 ? (
            <></>
          ) : (
            <>
              <div className=" flex w-full items-center justify-end">
                <button
                  className=" rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500 "
                  type="button"
                  onClick={() => removeInputFields(index)}
                >
                  Remove Sub Topic
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg  p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
          Add Topic
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="name"
              type="text"
              placeholder="Topic Name"
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                setName(newValue);

                // Automatically generate slug based on the name
                const newSlug = newValue
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, "-") // Replace whitespace with hyphen
                  .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                setSlug(newSlug);
              }}
            />
            {err && err.name ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.name}{" "}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="slug"
              type="text"
              placeholder="topic-slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            />
            {err && err.slug ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.slug}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="mb-4 flex  gap-5">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Select Grade
            </label>
            {grade[0] ? (
              <>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={gradeId}
                  onChange={(e) => {
                    setGradeId(e.target.value);
                  }}
                >
                  <option value={""}> Select Grade... </option>
                  {grade.map((value, index) => {
                    return (
                      <>
                        <option value={value.id}>{value.name}</option>
                      </>
                    );
                  })}
                </select>
                {err && err.grade_id ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.grade_id}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full md:w-1/2 lg:w-full">
            <label
              className="mb-2 block text-sm font-bold "
              htmlFor="categoryType"
            >
              Select Subject
            </label>
            {subject[0] ? (
              <>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="categoryType"
                  value={subjectId}
                  onChange={(e) => {
                    setSubjectId(e.target.value);
                  }}
                >
                  <option value={""}> Select Subject... </option>
                  {subject.map((value, index) => {
                    return (
                      <>
                        <option key={index} value={value.id}>
                          {" "}
                          {value.name}{" "}
                        </option>
                      </>
                    );
                  })}
                </select>
                {err && err.subject_id ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.subject_id}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Description
          </label>
          <CkEditorComp
            handleData={(e) => {
              setDescription(e);
            }}
            predata={description ? description : ""}
          />
          {err && err.description ? (
            <>
              <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                {" "}
                Description is Required!
              </p>{" "}
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
          {renderInputFields()}
          {err && err["options"] ? (
            <>
              {" "}
              <p className=" pl-1 pt-1 text-sm text-red-500">
                {" "}
                {err["options"]}
              </p>{" "}
            </>
          ) : (
            <></>
          )}

          <div
            className=" mt-3 w-fit rounded-md bg-primaryBlue  px-5 py-2 text-white "
            onClick={() =>
              setOptionstopic([
                ...optionstopic,
                {
                  title: [],
                  description: [],
                },
              ])
            }
          >
            Add Sub Topic
          </div>
          <LoaderComp submited={submited} />
        </div>
        {/* Additional fields can be added similarly */}

        <div className="flex justify-end">
          <button
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
            type="submit"
            onClick={handleSubmit}
          >
            Update Topic
          </button>
        </div>
        <LoaderComp submited={submited} />
      </div>

      <ToastContainer />
    </>
  );
}
