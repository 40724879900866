// DropArea.js
import React from "react";
import { useDrop } from "react-dnd";
import { MdOutlineRemoveDone } from "react-icons/md";
const DropArea = ({ selectedItems, onDrop, handleDroppedRemove }) => {
  const [, drop] = useDrop({
    accept: "ITEM",
    drop: (item) => onDrop(item.id),
  });

  const HandleRemove = (id) => {
    handleDroppedRemove(id);
  };
  const trimString = (str) => {
    if (str.length > 180) {
      return str.slice(0, 180) + "...";
    } else {
      return str;
    }
  };
  return (
    <div ref={drop} className=" h-[490px] rounded-xl p-3">
      {selectedItems[0] ? (
        <>
          {selectedItems.map((item) => (
            <>
              <div className=" bg- relative mb-2 rounded-sm border-2  border-gray-200 bg-primaryBlue/40 px-2 py-2 ">
                {item.id}. {trimString(item.text)}
                <button
                  onClick={() => HandleRemove(item.id)}
                  type="button"
                  className=" absolute bottom-0 right-0 flex h-8 w-8 items-center justify-center rounded-lg bg-gray-200 text-red-600 hover:bg-red-300        hover:text-white "
                >
                  <MdOutlineRemoveDone />
                </button>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <div className=" flex h-full w-full  items-center justify-center text-4xl font-bold text-gray-300 ">
            <p> Drag And Drop Quiestions Hare</p>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default DropArea;
