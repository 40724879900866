
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { importAlldata } from "proxyUrl";
import LoaderComp from "components/common/LoaderComp";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";

function disableBackButton() {
  window.history.pushState(null, null, window.location.pathname);
  window.addEventListener("popstate", function (event) {
    window.history.pushState(null, null, window.location.pathname);
  });
}
const Dashboard = () => {

    
  const logged_user_info = useSelector((state) => state.user.user);
  const isAdmin = logged_user_info?.userInfo?.role_id ===  1;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [vnayaQuestion, setVnayaQuestion] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [questionId, setQuestionId] = useState([]);
  const [update, setUpdate] = useState(0);
  const [filter, setFilter] = useState(0);
  const [submited, setSubmited] = useState(false);

  const importdata = async (e) => {
    e.preventDefault();
    setSubmited(true);

    await axios
      .post(
        importAlldata,
        {
          data: vnayaQuestion
        }

      )
      .then((res) => {
        setSubmited(false);

        updateQuestion();
        console.log(res.data)


      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);

    await axios
      .post(
        'https://ops1.vnaya.com/api/get-question',
        {
          start_date: `${startDate.getFullYear()}-0${startDate.getMonth() + 1}-${startDate.getDate()}`,
          end_date: `${endDate.getFullYear()}-0${endDate.getMonth() + 1}-${endDate.getDate()}`,
        }

      )
      .then((res) => {
        setUpdate(0)
        console.log(res.data)
        if (res.data.status == 200) {
          setVnayaQuestion(res.data.data);
          setQuestionId(res.data.data?.question_id);
          setTotalQuestion(res.data.count);
          setSubmited(false);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateQuestion = async (e) => {
    // e.preventDefault();
    await axios
      .post(
        'https://ops1.vnaya.com/api/update-question',
        {
          start_date: `${startDate.getFullYear()}-0${startDate.getMonth() + 1}-${startDate.getDate()}`,
          end_date: `${endDate.getFullYear()}-0${endDate.getMonth() + 1}-${endDate.getDate()}`,
        }
      )
      .then((res) => {
        if (res.data.status == 200) {
          setUpdate(1);
          setSubmited(false);
        }
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    disableBackButton();
  }, []);
  return (
    <div>
      <LoaderComp submited={submited} />
      {/* Card widget */}


      {
        isAdmin ? (
          <>
          <div className="mt-5 flex items-center justify-between px-2 mb-5  ">
        <div className=" flex items-center justify-start gap-3 ">
          <DatePicker className=" dark:bg-navy-800 dark:text-white bg-white px-3 py-2 rounded-full " selected={startDate} onChange={(date) => setStartDate(date)} />
          <DatePicker className=" dark:bg-navy-800 dark:text-white bg-white px-3 py-2 rounded-full " selected={endDate} onChange={(date) => setEndDate(date)} />

        </div>

        <div className="">
          <button
            className="focus:shadow-outline rounded-full dark:bg-navy-800   bg-blueSecondary py-2 px-4 font-bold text-white hover:bg-lightPrimary focus:outline-none"
            type="submit"
            onClick={handleSubmit}
          >
            Sync Data
          </button>
        </div>
      </div>
      {totalQuestion != 0 ?
        <button type="button" disabled={update} class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" onClick={importdata}>Import</button>
        : ''}
      {update == 1 ? <button type="button" disabled class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"    >Completed</button> : ''}
      <div href="#" class="py-3 rounded-full px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white  border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-navy-800 dark:text-white dark:border-navy-600 dark:hover:text-white dark:hover:bg-navy-800">Total :  {totalQuestion ? totalQuestion : 0}</div>
      {/* <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">

        {vnayaQuestion ? vnayaQuestion.map((question) => (
          <>
            <li class="items-center space-x-3 rtl:space-x-reverse p-5 bg-white	">
              <div className="flex mb-2">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400 mr-2 relative top-[5px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <div dangerouslySetInnerHTML={{ __html: question ? question.question : '' }} />
              </div>
              <div></div>
              <span>
              </span>
              <div class="inline-flex items-center px-3 py-1 text-xs font-medium text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{question.name}</div>
              <div class="inline-flex items-center px-3 py-1 text-xs font-medium text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{question.subjectsname}</div>

            </li></>
        ))
          : 'No data Found'
        }


      </ul> */}
      {/* testing ui */}
      <div className=" h-screen w-full flex items-center justify-center ">
      </div>
          </>
        ) : <></>
      }
    </div>
  );
};

export default Dashboard;
