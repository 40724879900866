import React, { useState, useEffect } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
// import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/avatar4.png";
import { FaHome } from "react-icons/fa";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { useDispatch } from "react-redux";
import { dark } from "store/themeSlice";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);

  //getting user data from localstorage..{

  const dispatch = useDispatch();
  useEffect(() => {}, []);
  return (
    <nav className="sticky top-4 z-40 flex  flex-row flex-wrap items-center justify-between rounded-2xl bg-white/10 p-2  backdrop-blur-xl dark:bg-navy-800 ">
      {/* <div className="nav-top-line absolute top-0 left-0 h-2 w-full bg-primaryBlue dark:bg-navy-800"></div> */}
      <div className="ml-[6px]">
        <div className="flex h-6 w-full  items-center justify-start pt-1">
          <a
            className="flex items-center justify-start gap-2 text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href="/ "
          >
            <FaHome className="text-2xl text-primaryBlue  dark:text-white" />{" "}
            <span className=" text-xl font-bold text-primaryBlue dark:text-white ">
              Home
            </span>
            <span className="mx-1 text-sm  text-primaryBlue hover:text-navy-700 dark:text-white">
              <MdOutlineDoubleArrow className=" text-2xl" />
            </span>
          </a>
          {/* BUG FIXED GAGAN */}
          <Link
            className=" text-xl font-bold capitalize text-primaryBlue  hover:underline dark:text-white dark:hover:text-white"
            to={`/admin/${brandText}`}
          >
            {brandText}
          </Link>
        </div>
        {/* <p className="shrink text-[33px] capitalize text-primaryBlue dark:text-white">
          <Link
            to="#"
            className="text-[28px] font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p> */}
      </div>

      <div className="relative mt-[3px] flex h-[61px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-6  py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
              dispatch(dark(false));
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
              dispatch(dark(true));
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {"userItem"}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a>
                <Link
                  to="/auth/sign-out"
                  href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </Link>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
