import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill, RiShowersFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { courseAllData, courseDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../components/common/Pagination";
import LoaderComp from "components/common/LoaderComp";

export default function Index() {
  const [courseData, setCourseData] = useState(null);
  const [submited, setSubmited] = useState(true);

  // Gagan
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(10);

  // getting all data tags starts----------------------
  const getAllCategorysData = async () => {
    await axios
      .get(courseAllData, {
        withCredentials: true,
      })
      .then((res) => {
        setCourseData(res.data.data);
        setSubmited(false);
      })
      .catch((err) => {
        setCourseData(null);
      });
  };

  // Gagan Bug fixed
  console.log(currentPage, "This is currwetnpageeeeeeeeeeee");
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = courseData
    ? courseData.slice(indexOfFirstRecord, indexOfLastRecord)
    : "";
  const nPages = courseData
    ? Math.ceil(courseData?.length / recordsPerPage)
    : 0;

  const handleDelete = async (id) => {
    await axios
      .delete(`${courseDelete}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Course Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllCategorysData();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.success("Course Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllCategorysData();
    // console.log(courseData,'courseDatacourseData')
  }, []);

  console.log(nPages, "nPagesnPages");
  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Course List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/course/add"}
            >
              {" "}
              Add Course{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Image
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Name
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Slug
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Price
                </th>

                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Status
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <LoaderComp submited={submited} />
              {currentRecords.length == 0 ? (
                <tr>
                  <td class="text-center" colspan="7">
                    {" "}
                    No Data Found
                  </td>
                </tr>
              ) : currentRecords ? (
                <>
                  {currentRecords.map((course) => (
                    <tr key={course.id}>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {course.image ? (
                          <img height="50" width="50" src={`${course.image}`} />
                        ) : (
                          <img height="50" width="50" src="/course.jpg" />
                        )}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {course.name}{" "}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        /{course.slug}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">{`$ ${course.sale_price}`}</td>

                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {course.status == "active" ? (
                          <p className=" w-fit rounded-full  bg-blueSecondary py-1 px-4 text-sm text-white">
                            {course.status}
                          </p>
                        ) : (
                          <p className=" w-fit  rounded-full bg-red-700  py-1 px-4 text-sm text-white">
                            {course.status}
                          </p>
                        )}
                      </td>

                      <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                        <Link
                          to={`/admin/course/edit/${course.id}`}
                          className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                          // onClick={() => handleEdit(category.id)}
                        >
                          <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                        </Link>

                        <button
                          className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                          onClick={() => handleDelete(course.id)}
                        >
                          <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <td
                    colSpan="4"
                    className="border py-2 px-4   dark:border-navy-800   dark:text-white"
                  >
                    No Data
                  </td>
                </>
              )}
            </tbody>
          </table>
          {nPages != 1 ? (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            ""
          )}

          {/* pagination */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
