import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill, RiShowersFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { contentAllData, contentDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../components/common/Pagination";
import LoaderComp from "components/common/LoaderComp";

export default function Index() {
  const [contentData, setContentData] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage] = useState(10);
  const [submited, setSubmited] = useState(true);

  // getting all data tags starts----------------------
  const getAllCategorysData = async () => {
    await axios
      .get(contentAllData, {
        withCredentials: true,
      })
      .then((res) => {
        setContentData(res.data.data);
        setSubmited(false);
      })
      .catch((err) => {
        setContentData(null);
      });
  };

  // Gagan
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = contentData
    ? contentData.slice(indexOfFirstRecord, indexOfLastRecord)
    : "";
  const nPages = contentData
    ? Math.ceil(contentData?.length / recordsPerPage)
    : 0;

  const handleDelete = async (id) => {
    await axios
      .delete(`${contentDelete}/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Content Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllCategorysData();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.success("Content Not Found!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (err.response.status === 500) {
          toast.success("Internal Server Error!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllCategorysData();
    // console.log(courseData,'courseDatacourseData')
  }, []);

  console.log(nPages, "nPagesnPages");
  return (
    <>
      {/* <div><Link to='/admin/categories/add'>Add Category</Link></div>
    <div><Link to='/admin/categories/edit/7'>Edit Category</Link></div> */}
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Content List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/content/add"}
            >
              {" "}
              Add Content{" "}
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <LoaderComp submited={submited} />

          <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
            <thead className=" overflow-hidden rounded-lg text-white">
              <tr>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Title
                </th>
                <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
                  Description
                </th>

                <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRecords ? (
                <>
                  {currentRecords.map((content) => (
                    <tr key={content.id}>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        {content.name}{" "}
                      </td>
                      <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content ? content.description : "",
                          }}
                        />
                      </td>
                      <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
                        <Link
                          to={`/admin/content/edit/${content.id}`}
                          className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                        >
                          <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                        </Link>
                        <button
                          className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                          onClick={() => handleDelete(content.id)}
                        >
                          <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <td
                    colSpan="4"
                    className="border py-2 px-4   dark:border-navy-800   dark:text-white"
                  >
                    No Data
                  </td>
                </>
              )}
            </tbody>
          </table>
          {nPages != 1 ? (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            ""
          )}

          {/* pagination */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
